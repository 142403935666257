<div fxLayout="column" *ngIf="!!control" class='control-container'>

    <div fxLayout="row" fxLayoutAlign="space-between end">
        <mat-label [class.comment-height]="!!commentHeight">{{ labelText }}</mat-label>
        <mat-icon *ngIf="!!toolTip" [matTooltip]="toolTip">help</mat-icon>
    </div>
    <mat-hint *ngIf="!!comment" [innerHtml]="'(' + comment + ')'"></mat-hint>

    <div fxLayout="row">
        <!-- <span fxFlex="10" *ngIf="!toolTip" ></span> -->
        <!-- <mat-icon fxFlex="10" *ngIf="!!toolTip" [matTooltip]="toolTip">help</mat-icon> -->

        <mat-form-field [fxFlex]="!!calculation ? 45 : 100" [hideRequiredMarker]="!isRequired" appearance="outline">
            <input *ngIf="isRequired" [options]="options" currencyMask matInput [formControl]="formControl"
                [placeholder]="!control.disabled ? labelText : ''" title="{{labelText}}" required />
            <input *ngIf="!isRequired" [options]="options" currencyMask matInput [formControl]="formControl"
                [placeholder]="!control.disabled ? labelText : ''" title="{{labelText}}" />
            <mat-error cxad-form-field-error *ngIf="control.invalid" [control]="control"></mat-error>
        </mat-form-field>
        <ng-container *ngIf="!!calculation">
            <div fxFlex="10" fxLayout="row" fxLayoutAlign="center center" class="equal-sign">
                =
            </div>
            <!-- <span fxFlex="10">=</span> -->
            <mat-form-field fxFlex="45" appearance="outline">
                <!-- <span class="calculation-label">{{ calculation }}</span>+ -->
                <input matInput [value]="calculation" readonly class="calculation" title="{{calculation}}">
            </mat-form-field>
        </ng-container>
    </div>
</div>