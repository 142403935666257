<div class="control-container" fxLayout="column" *ngIf="!!phoneForm && !!control" [formGroup]="phoneForm">

    <mat-label>{{ labelText }}</mat-label>
    <div fxLayout="row">
        <mat-form-field fxFlex="100px" appearance="outline">
            <mat-select formControlName="areaCode">
                <mat-option *ngFor="let entity of areaCodeList" [value]="entity.value">
                    {{entity.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="100" [hideRequiredMarker]="!isRequired" appearance="outline">
            <input matInput type="phone" formControlName="number" [required]="!!isRequired"
                [errorStateMatcher]="phoneErrorStateMatcher" [placeholder]="!control.disabled ? labelText : ''"
                title="{{labelText}}" />
            <mat-hint *ngIf="!!comment">{{ comment }}</mat-hint>
            <mat-error cxad-form-field-error [control]="control"></mat-error>
        </mat-form-field>
    </div>
</div>