import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

/**
 * Komponente, welche eine Input Feld darstellt
 */
@Component({
    selector: 'cxad-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit, AfterViewInit {
    @Input() public control!: AbstractControl;
    @Input() public labelText!: string;
    @Input() public comment: string | undefined;
    @Input() public type = 'text';
    @Input() public readonly = false;
    @Input() public toolTip: string | undefined;
    @Input() public min = 0;
    @Input() public isFocused = false;
    @Input() public isHorizontal = false;

    @Output() public blurred = new EventEmitter();
    @ViewChild('inputElement') private inputElement: ElementRef | undefined;

    public isRequired: boolean | undefined;

    /**
     * gibt den aktuellen AbstractControl als FormControl zurück
     *
     * @returns {FormControl} control
     */
    public get formControl(): FormControl {
        return this.control as FormControl;
    }

    /**
     * standard Konstruktor
     *
     * @param {ChangeDetectorRef} cRef ChangeDetectorRef injector
     */
    public constructor(private cRef: ChangeDetectorRef) { }

    /**
     * Angular Hook zum initialisieren
     */
    public ngOnInit(): void {
        // @see https://stackoverflow.com/a/43904237
        const validators = !!this.control && !!this.control.validator ? this.control.validator(new FormControl()) : {};
        this.isRequired = !!validators && ('required' in validators);

        if (this.readonly) {
            this.control.disable();
        }
    }

    /**
     * Angular Hook nachdem die View geladen wurde
     */
    public ngAfterViewInit() {
        if (this.isFocused) {
            this.inputElement?.nativeElement.focus();
            this.cRef.detectChanges();
        }
    }

    /**
     * Verhindert die Eingabe von Nicht Zahlen
     * 
     * @param {KeyboardEvent} event Tastatur Event
     */
    // eslint-disable-next-line class-methods-use-this
    public onlyNumbers(event: KeyboardEvent): void {
        const specialKeys: string[] = ['Backspace', 'Delete', 'Control', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft'];
        const allowdWithCtr = ['x', 'c', 'v']

        if (isNaN(parseInt(event.key, 10)) && !specialKeys.includes(event.key) && (!allowdWithCtr.includes(event.key) && !event.ctrlKey)) {
            event.preventDefault();
        }
    }

    /**
     * wenn die Eingabe focus verliert
     */
    public onBlur() {
        this.blurred.emit();
    }

    /**
     * Enter wird gedrückt
     */
    public onEnter() {
        this.inputElement?.nativeElement.blur();
    }
}
