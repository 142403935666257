import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { GlobalSettings, HelperService } from '@ucba/sdk';
import { RateCalculationDialogComponent } from '@ucba/sdk/components';
import { RoutingParams } from '@ucba/sdk/enums';
import { IRateCalculationData } from '@ucba/sdk/interfaces';
import { ICustomerModel } from '@ucba/sdk/models';
import { Dispose } from '@ucba/sdk/statemanagement/actions';
import { CustomerState, FinancingMapState, MasterDataState } from '@ucba/sdk/statemanagement/states';
import { Observable, filter, map } from 'rxjs';

import { ApplicationState } from '../../statemanagement/states';

/**
 * Komponente welche die Tabbar enthält für die Navigation
 */
@Component({
    selector: 'cxad-tab-navigation',
    templateUrl: './tab-navigation.component.html',
    styleUrls: ['./tab-navigation.component.scss'],
})

export class TabNavigationComponent implements OnInit {
    @Select(ApplicationState.isLoggedIn)
    public isLoggedIn$: Observable<boolean> | undefined;

    @Select(CustomerState.current)
    public customer$: Observable<ICustomerModel | null> | undefined;

    public hideHeader = false;

    public globalSettings = GlobalSettings;

    /**
     * Standard Konstruktor
     *
     * @param { Router } router Router injector
     * @param { Store } store Store injector
     * @param {NotificationService} notification NotificationService injector
     * @param {TranslateService} translate TranslateService injector
     * @param {MatDialog} dialog MatDialog injector
     */
    public constructor(
        private router: Router,
        private store: Store,
        private notification: NotificationService,
        private translate: TranslateService,
        private dialog: MatDialog,
    ) { }

    @ViewChild('drawer', { static: false })
    public drawer?: MatDrawer;

    public navLinks = [
        { location: '/financing', label: 'components.navigation.myFinancing', icon: 'apps' },
        { location: '/financing/status', label: 'components.navigation.status', icon: 'list_alt' },
    ];

    /**
     * Angular Hook zum initialisieren
     *
     */
    public ngOnInit(): void {
        this.hideHeader = this.router.url.endsWith(RoutingParams.PRIVACY_ROUTE);
        this.router.events.pipe(
            filter(ev => ev instanceof NavigationEnd),
            map(ev => (ev as NavigationEnd).url),
        ).subscribe(url => {
            this.hideHeader = url.endsWith(RoutingParams.PRIVACY_ROUTE);
        });
    }

    /**
     * Öffnet/Schließt das Sidenav
     *
     * @returns {Promise<void>} Void Promise
     */
    public async toggleDrawer(): Promise<void> {
        if (this.drawer) {
            await this.drawer.toggle();
        }
    }

    /**
     * wenn Logoutbutton gedrückt
     * 
     * @returns {Promise} Navigations Promise
     */
    public onLogout(): Promise<boolean> {
        this.store.dispatch(Dispose);
        GlobalSettings.token = null;
        return this.router.navigateByUrl('logout');
    }

    /**
     * Aktion wenn auf das Icon gedrückt wird, welches die fehlende Hubverbindung signalisiert
     */
    public onHubWarningClicked(): void {
        this.notification.alert(
            this.translate.instant('applicationTranslations.noHubHeader'),
            this.translate.instant('applicationTranslations.noHubNote'),
        );
    }

    /**
     * open the rate Calculator
     */
    public openCalculator(): void {
        const brutto = this.store.selectSnapshot(FinancingMapState.longFinancingRequirementBrutto);
        const fm = this.store.selectSnapshot(FinancingMapState.current);
        const referenceInterest = this.store.selectSnapshot(FinancingMapState.referenceInterest);
        const cfg = this.store.selectSnapshot(MasterDataState.determineFinancingConfiguration)();

        const dialogConfig: MatDialogConfig = {
            autoFocus: false,
            data: {
                amount: brutto > 10000 ? brutto : undefined,
                debitRate: HelperService.hasValue(fm?.requestedDebitRate) ? fm?.requestedDebitRate : referenceInterest > 0 ? referenceInterest : undefined,
                duration: !!fm && HelperService.hasValue(fm?.assumedDuration) ? fm.assumedDuration / 12 : undefined,
                calculationVersion: fm?.calculationVersion,
                bankAccountFee: cfg?.bankAccountFee,
            } as IRateCalculationData,
        }

        this.dialog.open(RateCalculationDialogComponent, dialogConfig);
    }
}
