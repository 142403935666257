<div class="imprint-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>
                {{ 'shared.components.supportCard.title' | translate }}
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div>
                <button color="primary" class="copy-button" (click)="copySystemInfo()" mat-flat-button>
                    {{ 'shared.components.supportCard.copySystemInfoBtn' | translate }}
                    <mat-icon> content_copy </mat-icon>
                </button>
                <p>
                    {{ 'shared.components.supportCard.appVersion' | translate}}: {{ version }}
                    <span *ngIf="showMetaData">
                        {{ backendUrl }} {{ buildDate }}
                    </span>
                </p>
                <p class="subtitle">{{ 'shared.components.supportCard.system' | translate }}</p>
                <p>
                    {{ browser.microsoft }}
                    <br>
                    {{ browser.chrome }}
                    <br>
                    {{ browser.firefox }}
                    <br>
                    {{ browser.safari }}
                </p>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{ 'shared.components.imprintCard.title' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div>
                <p>{{ 'shared.components.imprintCard.declarations' | translate }}:
                    <br>{{ 'shared.components.imprintCard.company' | translate }}
                    <br>{{ 'shared.components.imprintCard.address' | translate }}
                </p>

                <p class="subtitle">{{ 'shared.components.imprintCard.contact' | translate }}:</p>
                <p>
                    {{ 'shared.components.imprintCard.fax' | translate }}
                    {{ 'shared.components.imprintCard.faxNumber1' | translate }}
                    <br>{{ 'shared.components.imprintCard.fax' | translate }}
                    {{ 'shared.components.imprintCard.faxNumber2' | translate }}
                    <br>{{ 'shared.components.imprintCard.mail' | translate }}
                    {{ 'shared.components.imprintCard.mailAddress' | translate }}
                </p>

                <p class="subtitle">{{ 'shared.components.imprintCard.representative' | translate }}:</p>
                <p>{{ 'shared.components.imprintCard.person1' | translate }}
                    <br>{{ 'shared.components.imprintCard.person2' | translate }}
                    <br>{{ 'shared.components.imprintCard.person3' | translate }}
                </p>

                <p class="subtitle">{{ 'shared.components.imprintCard.register' | translate }}:</p>
                <p>{{ 'shared.components.imprintCard.registerCourt' | translate }}
                    <br>{{ 'shared.components.imprintCard.registerNumber' | translate }}
                </p>

                <p class="subtitle">{{ 'shared.components.imprintCard.tax' | translate }}:</p>
                <p>{{ 'shared.components.imprintCard.taxLaw' | translate }}
                    <br>{{ 'shared.components.imprintCard.taxNumber' | translate }}
                </p>

                <p class="subtitle">{{ 'shared.components.imprintCard.responsible' | translate }}:</p>
                <p>{{ 'shared.components.imprintCard.responsiblePerson' | translate }}</p>

                <p class="subtitle">{{ 'shared.components.imprintCard.dispute' | translate }}:</p>
                <p>{{ 'shared.components.imprintCard.disputeText1' | translate }}
                    <a href="{{ 'shared.components.imprintCard.disputeLink' | translate }}">
                        {{ 'shared.components.imprintCard.disputeLink' | translate }}
                    </a>
                    {{ 'shared.components.imprintCard.disputeText2' | translate }}
                    <br>
                    <br>{{ 'shared.components.imprintCard.disputeText3' | translate }}
                </p>

                <p class="subtitle">{{ 'shared.components.imprintCard.liabilityContent' | translate }}</p>
                <p>{{ 'shared.components.imprintCard.liabilityContentText1' | translate }}
                    <br>
                    <br>{{ 'shared.components.imprintCard.liabilityContentText2' | translate }}
                </p>

                <p class="subtitle">{{ 'shared.components.imprintCard.liabilityLinks' | translate }}</p>
                <p>{{ 'shared.components.imprintCard.liabilityLinksText1' | translate }}
                    <br>
                    <br>{{ 'shared.components.imprintCard.liabilityLinksText2' | translate }}
                </p>

                <p class="subtitle">{{ 'shared.components.imprintCard.copyright' | translate }}</p>
                <p>{{ 'shared.components.imprintCard.copyrightText1' | translate }}
                    <br>
                    <br>{{ 'shared.components.imprintCard.copyrightText2' | translate }}
                </p>
            </div>

            <button mat-flat-button color="primary" (click)="back()">
                <mat-icon>arrow_back</mat-icon>
                {{ 'shared.components.imprintCard.back' | translate }}
            </button>
        </mat-card-content>
    </mat-card>
</div>