<div fxLayout="column" class="control-container">
    <mat-label>{{ labelText }}</mat-label>
    <mat-form-field *ngIf="!!control" [hideRequiredMarker]="!isRequired" appearance="outline">
        <input *ngIf="isRequired" matInput [matDatepicker]="picker" [formControl]="formControl"
            [placeholder]="!control.disabled ? labelText : ''" title="{{labelText}}" required>
        <input *ngIf="!isRequired" matInput [matDatepicker]="picker" [formControl]="formControl"
            [placeholder]="!control.disabled ? labelText : ''" title="{{labelText}}">
        <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
        <mat-datepicker [calendarHeaderComponent]="customeHeaderComponent" #picker>
            <mat-datepicker-actions>
                <button mat-button (click)="selectToday(picker)">Heute</button>
                <button mat-flat-button color="primary" matDatepickerApply>Übernehmen</button>
            </mat-datepicker-actions>
        </mat-datepicker>
        <mat-hint *ngIf="!!comment">{{ comment }}</mat-hint>
        <mat-error cxad-form-field-error *ngIf="control.invalid" [control]="control"></mat-error>
    </mat-form-field>
</div>