import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatCalendar, MatDatepicker } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { GlobalSettings } from '@ucba/sdk';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { Subject, takeUntil } from 'rxjs';

interface IDateOption {
    value: number;
    viewValue: string;
}


/**
 * Komponente, welche einen Datepicker darstellt
 */
@Component({
    selector: 'cxad-date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.scss'],
    providers: [
        provideMomentDateAdapter(GlobalSettings.CUSTOM_DATE_FORMAT),
    ],
})
export class DateComponent implements OnInit {

    @Input() public control!: AbstractControl;
    @Input() public labelText!: string;
    @Input() public comment: string | undefined;

    public isRequired: boolean | undefined;

    public customeHeaderComponent = CustomCalenderHeaderComponent;

    /**
     * gibt den aktuellen AbstractControl als FormControl zurück
     *
     * @returns {FormControl} control
     */
    public get formControl(): FormControl {
        return this.control as FormControl;
    }

    /**
     * Angular Hook zum initialisieren
     */
    public ngOnInit(): void {
        // @see https://stackoverflow.com/a/43904237
        const validators = !!this.control && !!this.control.validator ? this.control.validator(new FormControl()) : {};
        this.isRequired = !!validators && ('required' in validators);
    }


    /**
     * Setzt das Datum auf den aktuellen Tag und schließt den Datepicker
     * 
     * @param {MatDatepicker<unknown>} picker - Datepicker
     */
    public selectToday(picker: MatDatepicker<unknown>): void {
        const currentDate = new Date().toISOString();
        this.formControl.setValue(currentDate);
        picker.close();
    }

}

/**
 * Custom Header für den Datepicker
 */
@Component({
    selector: 'cxad-calendar-header',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatSelectModule, NgFor, FlexLayoutModule],
    template: `
    <div class="example-header">
        <div class="select-container" fxLayout="column">
            <mat-label>Jahr</mat-label>
            <mat-form-field appearance="outline">
                <mat-select placeholder="Jahr" [(value)]="selectedYear" (selectionChange)="yearChanged()">
                    <mat-option *ngFor="let year of years" [value]="year">{{year}} 
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="select-container" fxLayout="column">
            <mat-label>Monat</mat-label>
            <mat-form-field appearance="outline">
                <mat-select [(value)]="selectedMonth" (selectionChange)="monthChanged()">
                    <mat-option *ngFor="let month of months" [value]="month.value">{{month.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    `,
    styles: [`
    .example-header {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        justify-content: space-between;

        .select-container {
            max-width: 180px;
            width: 40vw;
        }
    }
    `],
})
export class CustomCalenderHeaderComponent<D> implements OnDestroy {
    private destroyed = new Subject<void>();

    public selectedYear!: number;
    public selectedMonth!: number;

    public years = Array.from({ length: 300 }, (index, i) => i + 1900);
    public months: IDateOption[] = [
        { value: 0, viewValue: 'Januar' },
        { value: 1, viewValue: 'Februar' },
        { value: 2, viewValue: 'März' },
        { value: 3, viewValue: 'April' },
        { value: 4, viewValue: 'Mai' },
        { value: 5, viewValue: 'Juni' },
        { value: 6, viewValue: 'Juli' },
        { value: 7, viewValue: 'August' },
        { value: 8, viewValue: 'September' },
        { value: 9, viewValue: 'Oktober' },
        { value: 10, viewValue: 'November' },
        { value: 11, viewValue: 'Dezember' },
    ];

    /**
     * Standard Konstruktor
     * 
     * @param {MatCalendar} calendar - Kalender
     * @param {DateAdapter} dateAdapter - DateAdapter
     * @param {ChangeDetectorRef} cdr - ChangeDetectorRef
     */
    public constructor(
        private calendar: MatCalendar<D>,
        private dateAdapter: DateAdapter<D>,
        cdr: ChangeDetectorRef,
    ) {
        this.selectedYear = this.dateAdapter.getYear(this.calendar.activeDate);
        this.selectedMonth = this.dateAdapter.getMonth(this.calendar.activeDate);
        calendar.stateChanges.pipe(takeUntil(this.destroyed)).subscribe(() => {
            cdr.markForCheck()
            //this.selectedYear = this.dateAdapter.getYear(this.calendar.activeDate);
        });
    }

    /**
     * Angular Hook zum aufräumen
     */
    public ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

    /**
     * Jahr wurde geändert
     */
    public yearChanged() {
        this.calendar.activeDate = this.dateAdapter.addCalendarYears(this.calendar.activeDate, this.selectedYear - this.dateAdapter.getYear(this.calendar.activeDate));
    }


    /**
     * Monat wurde geändert
     */
    public monthChanged() {
        this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, this.selectedMonth - this.dateAdapter.getMonth(this.calendar.activeDate));
    }
}

