<waiter-component [isLoading]="isLoading" class="area-wrapper area-height">
    <div [class.apply-shadow]="!closed && addShadow" [class.shadow-bottom]="!closed" fxLayout="row"
        fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutGap.gt-sm="20px">
        <span class="area-title">{{ 'components.financingTab.documents.pageTitle' | translate }}</span>
        <div fxLayout="row" fxLayoutAlign="flex-end center" class="fixed-button">
            <mat-icon *ngIf="mustSendToVPC" class="resubmit-warning" fxFlex="15"
                matTooltip="{{ 'components.financingTab.documents.resubmitWarningTooltip' | translate }}"> error
            </mat-icon>
            <button mat-flat-button color="primary" (click)="sendDocumentsToFinProcess()" class="action-button"
                [fxFlex]="mustSendToVPC ? '80' : '100'" [disabled]='!mustSendToVPC'
                [class.invisible]='!readonly || closed'
                matTooltip="{{ 'components.financingTab.documents.resubmitTooltip' | translate }}">
                <mat-icon>upload_file</mat-icon>
                <span>{{ 'components.financingTab.documents.resubmit' | translate}}</span>
            </button>
        </div>
    </div>

    <cxad-read-only [class.shadow-bottom]="closed" [class.apply-shadow]="closed && addShadow"
        [isVisible]="closed"></cxad-read-only>

    <div class="scroll-wrapper" [class.readonly]="readonly" [class.open]="!readonly" #scrollWrapper>
        <div fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px"
            class="max-height">

            <!-- Unterlagen -->
            <div fxFlex="100" fxflex.gt-md="48">
                <div class="header">
                    <h1 fxHide fxHide.gt-sm="false">{{ 'components.financingTab.documents.documents' | translate }}</h1>
                    <h2 fxHide.gt-sm>{{ 'components.financingTab.documents.documents' | translate }}</h2>
                    <p fxHide fxHide.gt-xs="false">&nbsp;{{ 'components.financingTab.documents.documentsNote' |
                        translate }}</p>
                </div>
                <p class="doc-note" fxHide.gt-xs>{{ 'components.financingTab.documents.documentsNote' | translate }}</p>
                <mat-accordion multi>
                    <mat-expansion-panel *ngFor="let section of accordionListAreas" expanded=false>
                        <mat-expansion-panel-header class="disable-ripple">
                            <mat-panel-title>
                                {{ section.name }}
                                <span *ngIf="section.required">*</span>
                            </mat-panel-title>
                            <mat-panel-description fxLayout="row" fxLayoutAlign="end center">
                                <span class="check-doc" *ngIf="section.items.length > 0">
                                    <mat-icon>check</mat-icon>
                                    <span fxHide fxHide.gt-xs="false">{{ 'components.financingTab.documents.complete' |
                                        translate }}</span>
                                </span>
                                <span class="counter"> {{ section.items.length }} </span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="space-between stretch"
                            fxLayoutGap="10px">
                            <div fxFlex="33" class="margin-top word-break">
                                <span>{{ 'components.financingTab.documents.possibleDocuments' | translate }}</span>
                                <div [innerHTML]="section.description"></div>
                            </div>
                            <div fxFlex="33">
                                <label class="upload mdc-button mat-primary" [for]="section.areaId"
                                    [class.disabled-upload]="closed"
                                    matTooltip="{{ ('components.financingTab.documents.multiUploadDocumentFor' | translate :{name: section.name}) }}">
                                    <mat-icon> folder </mat-icon>
                                    <span>{{ 'components.financingTab.documents.selectDocument' |
                                        translate }}</span>
                                </label>
                                <input type="file" [id]="section.areaId" [accept]="inputAcceptString" hidden multiple
                                    (change)="newDocuments($event, section.area, section.metaType)"
                                    [disabled]="closed" />
                                <div class="dropdiv" (dragenter)="toggleHoverZone(section.metaType, false)"
                                    (dragleave)="toggleHoverZone(section.metaType, true)"
                                    [class.drag-active]="dropzonesHovered[section.metaType]" fxHide fxShow.gt-md
                                    (dragover)="onDragOver($event)"
                                    (dragleave)="toggleHoverZone(section.metaType, true)"
                                    [class.drag-active]="dropzonesHovered[section.metaType]" fxHide fxShow.gt-md
                                    (dragover)="onDragOver($event)"
                                    (drop)="onDropSuccess($event, section.area, section.metaType)">
                                    <label class=" custom-file-upload" [class.disabled-icon]='closed'>
                                        <input hidden type="file" />
                                        {{ 'components.financingTab.documents.dropDocuments' | translate }}
                                    </label>
                                </div>
                            </div>

                            <div fxFlex="33" class="margin-top">
                                <h2>{{ 'components.financingTab.documents.uplodedDocuments' | translate }}</h2>
                                <span *ngIf="!section.hasContent">{{
                                    'components.financingTab.documents.uplodedDocumentsNote' | translate }}
                                </span>
                                <mat-list>
                                    <mat-list-item *ngFor="let item of section.items"
                                        matTooltip="{{ 'components.financingTab.documents.showDocumentTooltip' | translate }}"
                                        (click)="showFile(item.fileId ?? '', item.name)">
                                        <span class="word-break" fxFlex="80" fxLayout="row"
                                            fxLayoutAlign="start center">
                                            {{ item.name }}
                                        </span>
                                        <span class="span-icon" fxFlex="20" fxLayout="row" fxLayoutAlign="flex-end">
                                            <button mat-icon-button
                                                (click)="deleteFile($event, item.documentId, item.fileId)"
                                                [disabled]="item.disableActionButtons"
                                                matTooltip="{{ 'components.financingTab.documents.toolTipDeleteDocument' | translate }}">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </span>
                                    </mat-list-item>
                                </mat-list>
                            </div>

                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>

            <!-- Unterschriften -->
            <div fxFlex="100" fxflex.gt-md="48">
                <h1 fxHide fxHide.gt-sm="false">{{ 'components.financingTab.documents.signatures' | translate }}</h1>
                <h2 fxHide.gt-sm>{{ 'components.financingTab.documents.signatures' | translate }}</h2>
                <mat-accordion multi>
                    <mat-expansion-panel *ngFor="let signature of signatureListAreas"
                        (click)="onSignDocumentClicked(signature)" [hideToggle]="!signature.hasContent"
                        [disabled]="!signature.hasContent" [class.no-content]="!signature.hasContent">
                        <mat-expansion-panel-header *ngIf="!signature.isHidden">
                            <mat-panel-title>
                                <span class="panel-title">
                                    {{ signature.name }}
                                </span>
                            </mat-panel-title>
                            <mat-panel-description>
                                <span class="check-doc" *ngIf="signature.isValid">
                                    <mat-icon>check</mat-icon>
                                    <span fxHide fxHide.gt-xs="false">{{ 'components.financingTab.documents.complete' |
                                        translate }} </span>
                                </span>
                                <span *ngIf="signature.hasContent || !signature.pageName" class="counter">
                                    {{ signature.items.length }}
                                </span>
                                <button mat-icon-button *ngIf="!signature.hasContent && !!signature.pageName"
                                    [matTooltip]="'components.financingTab.documents.signatureHover' | translate">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <div class=" image-upload" (click)="preventNavigate($event)">
                                    <label class="mdc-icon-button" [for]="signature.areaId">
                                        <mat-icon [class.disabled-icon]='closed'
                                            matTooltip="{{ ('components.financingTab.documents.multiUploadDocumentFor' | translate :{name: signature.name}) }}">
                                            file_upload
                                        </mat-icon>
                                    </label>
                                    <input [id]="signature.areaId" [accept]="inputAcceptString" type="file"
                                        multiple="multiple"
                                        (change)="newDocuments($event, signature.area, signature.documentType, signature.referenceId)"
                                        [disabled]="closed" />
                                </div>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <mat-list *ngFor="let item of signature.items" (click)="showFile(item.fileId ?? '', item.name)">
                            <mat-list-item>
                                <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between center"
                                    matTooltip="{{ 'components.financingTab.documents.showDocumentTooltip' | translate }}">
                                    <span>{{ item.name }}</span>
                                    <button mat-icon-button (click)="deleteFile($event, item.documentId, item.fileId)"
                                        [disabled]="item.disableActionButtons"
                                        matTooltip="{{ 'components.financingTab.documents.toolTipDeleteDocument' | translate }}">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </mat-list-item>
                        </mat-list>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>

        <div fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px"
            class="margin-top max-height">
            <!-- Nachzureichende Unterlagen -->
            <div fxFlex="100" fxflex.gt-md="48" *ngIf="isRequiredDocumentsVisible === true">
                <h1>{{ 'components.financingTab.documents.documentsToBeSubmittedLater' | translate }}</h1>
                <mat-accordion multi>
                    <mat-expansion-panel *ngFor="let section of requiredDocumentListAreas; trackBy: identifyer"
                        expanded=false>
                        <mat-expansion-panel-header class="documents-expansion-header disable-ripple"
                            *ngIf="!section.isHidden">
                            <mat-panel-title>
                                {{ section.name }}
                                <span>*</span>
                            </mat-panel-title>
                            <mat-panel-description>
                                <span class="counter"> {{ section.items.length }} </span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <ng-container>
                            <div fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="space-between stretch"
                                fxLayoutGap="10px">
                                <div fxFlex="33" [innerHTML]="section.description" class="margin-top word-break">
                                </div>
                                <div fxFlex="33">
                                    <label class="upload" [for]="section.areaId"
                                        matTooltip="{{ ('components.financingTab.documents.multiUploadDocumentFor' | translate :{name: section.name}) }}">
                                        <button color="primary" mat-flat-button>
                                            <mat-icon> folder </mat-icon>
                                            <span>{{ 'components.financingTab.documents.selectDocument' |
                                                translate }}</span>
                                        </button>
                                    </label>
                                    <input type="file" [id]="section.areaId" [accept]="inputAcceptString" hidden
                                        multiple
                                        (change)="newDocuments($event, section.area, section.documentType, section.referenceId)" />
                                    <div class="dropdiv"
                                        (dragenter)="toggleHoverZoneRequired(section.documentType, false)"
                                        (dragleave)="toggleHoverZoneRequired(section.documentType, true)"
                                        [class.drag-active]="dropzonesHoveredRequired[section.documentType]" fxHide
                                        fxShow.gt-md (dragover)="onDragOver($event)"
                                        (drop)="onDropSuccess($event, section.area, section.documentType, section.referenceId)">
                                        <label class=" custom-file-upload">
                                            <input hidden type="file" />
                                            {{ 'components.financingTab.documents.dropDocuments' | translate }}
                                        </label>
                                    </div>
                                </div>
                                <div fxFlex="33" class="margin-top">
                                    <h2>{{ 'components.financingTab.documents.uplodedDocuments' | translate }}</h2>
                                    <span *ngIf="!section.hasContent">{{
                                        'components.financingTab.documents.uplodedDocumentsNote' | translate }}
                                    </span>
                                    <mat-list *ngFor="let item of section.items">
                                        <mat-list-item (click)="showFile(item.fileId ?? '', item.name)">
                                            <span class="word-break" fxFlex="80" fxLayout="row"
                                                fxLayoutAlign="start center">
                                                {{ item.name }}
                                            </span>
                                            <span fxFlex="20" fxLayout="row" fxLayoutAlign="end center"
                                                fxLayoutAlign.gt-md="none">
                                                <mat-icon matListIcon
                                                    (click)="deleteFile($event, item.documentId, item.fileId)"
                                                    [class.disabled-icon]="item.disableActionButtons"
                                                    matTooltip="{{ 'components.financingTab.documents.toolTipDeleteDocument' | translate }}">
                                                    delete
                                                </mat-icon>
                                            </span>
                                        </mat-list-item>
                                    </mat-list>
                                </div>
                            </div>
                        </ng-container>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
    <div class="action-button-group">
        <button mat-flat-button color="primary" (click)="createActivity()" class="action-button fix-button"
            *ngIf="!readonly && !notSign && !closed"
            matTooltip="{{ 'components.financingTab.documents.sendToFinadvisory' | translate }}">
            <mat-icon class="action-icon">upload_file</mat-icon>
            <span>{{ 'components.financingTab.documents.sendToFinadvisory' | translate}}</span>
        </button>

        <button mat-flat-button color="primary" (click)="sendHouseholdDocuments()" class="action-button fix-button"
            [class.invisible]="!housholdCalculationStatus" [disabled]="missingHouseholdCalculationFiles"
            matTooltip="{{ 'components.financingTab.documents.submitHousholdCalculationTooltip' | translate }}">
            <mat-icon class="action-icon">upload_file</mat-icon>
            <span>{{ 'components.financingTab.documents.submitHousholdCalculation' | translate}}</span>
        </button>
    </div>

</waiter-component>