<div fxLayout="column" [fxLayout.gt-sm]="isHorizontal ? 'row' : 'column'" [fxLayoutAlign]="isHorizontal ? 'space-between stretch' : ''"
    *ngIf="!!control" class="control-container">
    <div fxLayout="column">
        <mat-label>{{ labelText }}</mat-label>
        <mat-hint *ngIf="!!comment">{{ comment }}</mat-hint>
    </div>

    <mat-form-field [hideRequiredMarker]="!isRequired" appearance="outline">
        <ng-container [ngSwitch]="type">
            <!-- bug with type number https://stackoverflow.com/a/59027629/4758334 -->
            <input *ngSwitchCase="'number'" [min]="min" matInput type="number" [formControl]="formControl" [class.right]="isHorizontal"
                [placeholder]="!control.disabled ? labelText : ''" [required]="!!isRequired"
                (keydown)="onlyNumbers($event)" (blur)="onBlur()" (keydown.enter)="onEnter()" title="{{labelText}}"
                #inputElement>

            <input *ngSwitchCase="'year'" matInput min="1900" max="2099" type="number" [class.right]="isHorizontal"
                [placeholder]="!control.disabled ? labelText : ''" [formControl]="formControl" [required]="!!isRequired"
                (blur)="onBlur()" (keydown.enter)="onEnter()" title="{{labelText}}" #inputElement>

            <input *ngSwitchDefault matInput [type]="type" [formControl]="formControl" [class.right]="isHorizontal"
                [placeholder]="!control.disabled ? labelText : ''" [required]="!!isRequired" (blur)="onBlur()"
                (keydown.enter)="onEnter()" title="{{labelText}}" #inputElement>
        </ng-container>

        <mat-error cxad-form-field-error *ngIf="control.invalid" [control]="control"></mat-error>
    </mat-form-field>
</div>

<hr fxHide [fxShow.gt-sm]="isHorizontal">