<mat-drawer-container class="tabnavigation-container">
    <mat-drawer #drawer position="end">
        <div fxLayout="column" *ngIf="isLoggedIn$ | async">
            <span routerLink="/financing" class="mobile-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
                matTooltip="{{ 'components.navigation.myFinancing' | translate }}">
                {{ 'components.navigation.myFinancing' | translate}}
            </span>
            <span routerLink="/financing/status" class="mobile-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}" matTooltip="{{ 'components.navigation.status' | translate }}">
                {{ 'components.navigation.status' | translate}}
            </span>
            <span class="mobile-link" (click)="openCalculator()"
                matTooltip="{{ 'components.navigation.ratesCalculator' | translate }}">
                <span>{{ 'components.navigation.ratesCalculator' | translate}}</span>
            </span>
            <span class="mobile-link" (click)="onLogout()" matTooltip="{{ 'logout.tooltip' | translate }}">
                {{ 'logout.text' | translate}}
            </span>
        </div>
    </mat-drawer>
    <mat-drawer-content fxLayout="column" fxLayoutAlign="space-between" *ngIf="!hideHeader; else privacy_statement">
        <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayoutAlign="start center">
                <mat-icon class="logo" routerLink="/welcome" fxHide.gt-sm svgIcon="bank-austria-mobile"
                    matTooltip="{{ 'components.navigation.home' | translate }}"></mat-icon>
                <mat-icon class="logo" routerLink="/welcome" fxHide fxHide.gt-sm="false" svgIcon="bank-austria"
                    matTooltip="{{ 'components.navigation.home' | translate }}"></mat-icon>
                <h4 fxHide.gt-sm class="app-title">{{ 'components.applicationTitle' | translate}}</h4>
                <h2 fxHide fxHide.gt-sm="false" class="app-title">{{ 'components.applicationTitle' | translate}}</h2>
            </div>
            <div fxLayoutAlign="end center">
                <mat-icon *ngIf="globalSettings.hubError" class="no-hub-icon"
                    (click)="onHubWarningClicked()">error</mat-icon>
                <ng-container *ngIf="isLoggedIn$ | async">
                    <div fxHide fxHide.gt-sm="false" fxLayout="row">
                        <a *ngFor="let link of navLinks" routerLink="{{ link.location }}"
                            [matTooltip]="link.label | translate">
                            <div class="tab">
                                <mat-icon> {{link.icon}} </mat-icon>
                                <span>{{ link.label | translate}}</span>
                            </div>
                        </a>
                        <a class="hover-pointer tab" (click)="openCalculator()"
                            matTooltip="{{ 'components.navigation.ratesCalculator' | translate }}">
                            <mat-icon> calculate </mat-icon>
                            <span>{{ 'components.navigation.ratesCalculator' | translate}}</span>
                        </a>
                        <a class="hover-pointer tab" (click)="onLogout()"
                            matTooltip="{{ 'logout.tooltip' | translate }}">
                            <mat-icon> logout </mat-icon>
                            <span>{{ 'logout.text' | translate}}</span>
                        </a>
                    </div>
                    <span fxHide.gt-sm class="hover-pointer material-icons md-36" (click)="toggleDrawer()">menu</span>
                </ng-container>
            </div>
        </div>

        <mat-tab-nav-panel #tabPanel>
            <router-outlet></router-outlet>
        </mat-tab-nav-panel>

        <cxad-footer></cxad-footer>
    </mat-drawer-content>
    <ng-template #privacy_statement>
        <router-outlet>
        </router-outlet>
    </ng-template>
</mat-drawer-container>