<div fxLayout="column" class="control-container">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <mat-label>{{ labelText }}</mat-label>
            <mat-hint *ngIf="!!comment">{{ comment }}</mat-hint>
        </div>
        <ng-content></ng-content>
    </div>

    <div fxLayout="row" fxLayoutGap="15" fxLayoutAlign="space-between">

        <mat-checkbox fxFlex="30" *ngIf="!!checkBoxControl" [formControl]="checkBoxFormControl">
            {{ checkBoxText }}
        </mat-checkbox>

        <mat-form-field fxFlex="55" appearance="outline">
            <input matInput [value]="value !== undefined ? (value | currency:'EUR':'symbol':'1.2-2') : ''" readonly/>
        </mat-form-field>
    </div>
</div>
