<waiter-component [isLoading]="(financings$ | async) === null" class="tabcontent area-wrapper">

    <h2 class="shadow-bottom" [class.apply-shadow]="addShadow">{{ 'components.welcome.statusMsg' | translate}}</h2>

    <div *ngIf="(financings$ | async) as financings" class="scroll-wrapper" #scrollWrapper>
        <mat-card class="financing-entry" *ngFor="let fm of financings">
            <mat-card-content>
                <ucba-financing-card fxFlex="32" fxFlex.lt-lg="100" [financingModel]="fm.financing"
                    (clicked)="openFinancing(fm.financing.id)" [buttons]="fm.buttons" [forceExpand]="true"
                    [cxAdvisory]="true">
                </ucba-financing-card>
            </mat-card-content>
            <mat-card-footer>
                <cxad-status-timeline [items]="fm.timelineItems"></cxad-status-timeline>
            </mat-card-footer>
        </mat-card>
    </div>
</waiter-component>