<form *ngIf="form && selectOptions" [formGroup]="form" (ngSubmit)="save()">
    <mat-dialog-content>
        <ng-container *ngIf="asFormArray(form, 'debtors').controls as debtors">
            <h2>{{ 'components.financingTab.household.deleteHousehold' | translate }}</h2>
            <div>{{ 'components.financingTab.household.deleteHouseholdMessage' | translate:{i:debtors.length} }}</div>
            <cxad-select *ngFor="let debtor of debtors" [allowNoSelection]="false" [array]="selectOptions"
                [control]="debtor.get('household')!" [customLabel]="debtor.get('name')?.value">
            </cxad-select>
        </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="column-reverse" fxLayout.gt-xs="row"
        fxLayoutAlign="space-evenly none">
        <button fxFlex="100" fxFlex.gt-xs="40" mat-flat-button type="button" (click)="dismiss()" color="secondary">
            {{ 'global.buttons.cancel' | translate }}
        </button>
        <button fxFlex="100" fxFlex.gt-xs="40" mat-flat-button type="submit" color="primary" [disabled]="form.invalid">
            {{ 'global.buttons.confirm' | translate }}
        </button>
    </mat-dialog-actions>
</form>