import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FileController } from '@ucba/sdk/controller';
import { FileHelper } from '@ucba/sdk/services';
import { take } from 'rxjs';

/**
 * Komponente an den Kunden Einwilligungserklärung bestätigen
 */
@Component({
    selector: 'cxad-consent',
    templateUrl: './consent.component.html',
    styleUrls: ['./consent.component.scss'],
})
export class ConsentComponent {
    /**
     * Standard Konstruktor
     *
     * @param {MatDialogRef} dialogRef dialog ref injector
     * @param {FileController} fileController FileController injector
     * @param {TranslateService} translate TranslateService injector
     */
    public constructor(
        public dialogRef: MatDialogRef<ConsentComponent>,
        private fileController: FileController,
        private translate: TranslateService,

    ) { }

    /**
     *download DSGVO
     */
    public onDownloadDsgvo(): void {
        this.fileController.downloadDsgvo()
            .pipe(take(1))
            .subscribe(blob => {
                if (!!blob) {
                    FileHelper.downloadFileFromBlob(blob, 'DSGVO').catch(e => { throw e; });
                }
                else {
                    throw new Error(this.translate.instant('modals.consent.content.warning'));
                }
            });
    }

    /**
     * prüfung, dass der Benutzer auf Bestätigung klickt
     */
    public onConfirm(): void {
        this.dialogRef.close(true);
    }
}
