<div class="tabcontent">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start" class="header shadow-bottom" [class.apply-shadow]="addShadow">
        <span>
            <h2 class="title">{{ 'components.welcome.myFinancing' | translate }}</h2>
            <p class="subtitle">
                {{ 'components.welcome.selfDisclosureMsg' | translate }}
            </p>
        </span>
    </div>
    <waiter-component [isLoading]="(financings$ | async) === null" class="area-wrapper financings-height">
        <div fxLayout="row wrap" fxLayoutGap.gt-xs="15px grid" fxLayout.lt-sm="column" class="scroll-wrapper" #scrollWrapper>
            <ucba-financing-card fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100"
                *ngFor="let fm of (financings$ | async)" [financingModel]="fm.financing" [forceExpand]="true"
                (clicked)="openFinancing(fm.financing.id)" [buttons]="fm.buttons" [cxAdvisory]="true">
            </ucba-financing-card>
        </div>
    </waiter-component>
</div>