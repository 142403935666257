<mat-dialog-content fxLayout="column" fxLayoutAlign="space-between">
    <h2>{{ 'modals.consent.content.title' | translate}}</h2>
    <div class="constent-scoll">
        <p>{{ 'modals.consent.content.text1' | translate}}</p>
        <p>{{ 'modals.consent.content.text2' | translate}}</p>
        <ul>
            <li>{{ 'modals.consent.content.text2_1' | translate}}</li>
            <li>{{ 'modals.consent.content.text2_2' | translate}}</li>
            <li>{{ 'modals.consent.content.text2_3' | translate}}</li>
            <li>{{ 'modals.consent.content.text2_4' | translate}}</li>
        </ul>
        <p>{{ 'modals.consent.content.text3' | translate}}</p>
        <p>{{ 'modals.consent.content.text4' | translate}}</p>
        <p>{{ 'modals.consent.content.text5' | translate}}</p>

        <p>
            <strong>
                {{ 'modals.consent.content.warning' | translate}}
            </strong>
            {{ 'modals.consent.content.text6' | translate}}
        </p>
    </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="column-reverse" fxLayout.gt-xs="row" fxLayoutAlign="space-evenly none">
    <button (click)="onDownloadDsgvo()" mat-flat-button color="secondary"
        matTooltip="{{ 'modals.consent.buttons.btnDSGVOinfoTitle' | translate}}">
        {{ 'modals.consent.buttons.btnDSGVOinfo' | translate}}
    </button>
    <button (click)="onConfirm()" color="primary" mat-flat-button [mat-dialog-close]
        matTooltip="{{ 'modals.consent.buttons.btnConfirm' | translate}}">
        {{ 'modals.consent.buttons.btnConfirm' | translate}}
    </button>
</mat-dialog-actions>