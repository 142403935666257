<div class="my-data-header" fxLayout="row" fxLayoutAlign="flex-start center">
    <h1>{{ 'components.myData.title' | translate}}</h1>
    <span>{{ 'components.myData.subTitle' | translate}}</span>
</div>

<mat-sidenav-container class="sidenav-container" autosize>
    <mat-sidenav class="sidenav" mode="side" opened="true" [disableClose]="true" [class.expanded]="isExpanded">
        <mat-nav-list class="nav-buttons">
            <mat-list-item class="sidenav-header" (click)="isExpanded=!isExpanded" [class.expanded]="isExpanded">
                <span matListItemTitle fxLayout="row"
                    [fxLayoutAlign]="isExpanded ? 'flex-start center' : 'center center'">
                    <mat-icon *ngIf="!isExpanded" mat-list-icon class="material-icons"
                        matTooltip="erweitern">unfold_more</mat-icon>
                    <mat-icon *ngIf="isExpanded" mat-list-icon class="material-icons"
                        matTooltip="schließen">unfold_less</mat-icon>
                    <span *ngIf="isExpanded" class="nav-label">Zuklappen</span>
                </span>
            </mat-list-item>
            <ng-container *ngFor="let item of navItems">
                <mat-list-item [matTooltip]="((item.tooltip$ | async) || '') | translate" [class.expanded]="isExpanded"
                    [routerLink]="item.routerLink" routerLinkActive #rla="routerLinkActive" [class.active]="rla.isActive">
                    <span matListItemTitle fxLayout="row"
                        [fxLayoutAlign]="isExpanded ? 'space-between center' : 'center center'">
                        <div>
                            <mat-icon class="material-icons" mat-list-icon [class.active]="rla.isActive"
                                [class.resubmit-warn]="item.routerLink === 'documents'"
                                [svgIcon]="item.icon + (!rla.isActive ? '-grey' : '')"
                                [matBadge]="(item.badgeContent$ | async)" matBadgeSize="small"
                                [matBadgeHidden]="isExpanded"></mat-icon>
                            <span *ngIf="isExpanded" class="nav-label" [class.active]="rla.isActive">
                                {{ item.label | translate }}</span>
                        </div>
                        <span class="notification" *ngIf="(item.badgeContent$ | async) && isExpanded"
                            [class.resubmit-warn]="item.routerLink === 'documents'">
                            {{(item.badgeContent$ | async)}}
                        </span>
                    </span>
                </mat-list-item>
            </ng-container>
        </mat-nav-list>
    </mat-sidenav>

    <router-outlet></router-outlet>

</mat-sidenav-container>