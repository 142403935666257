<div>
    <mat-card-content>
        <div *ngIf="!isPassCodeSended" class="div-width">
            <h2 mat-dialog-title>{{ 'modals.passcode.content.title' | translate}}</h2>

            <mat-dialog-content>
                <p>{{ 'modals.passcode.content.text1_1' | translate}}</p>
                <p>{{ 'modals.passcode.content.text1_2' | translate}}</p>
                <div class="phone">{{phoneNumber}}</div>
                <p></p>
                <p>
                    {{ 'modals.passcode.content.text2_1' | translate}}<br>
                    {{ 'modals.passcode.content.text2_2' | translate}}
                </p>

                <mat-error *ngIf="errorMessage" [innerHTML]="errorMessage"></mat-error>
            </mat-dialog-content>
            <mat-dialog-actions fxLayout="column-reverse" fxLayout.gt-xs="row"
                [fxLayoutAlign]="skipPasscodeCheckboxVisible ? 'space-evenly none' : 'flex-end none'" [class.noSkipPasscode]="!skipPasscodeCheckboxVisible">
                <mat-checkbox *ngIf="skipPasscodeCheckboxVisible" [(ngModel)]="skipPasscode">
                    {{ 'modals.passcode.content.skipPasscode' | translate }}
                </mat-checkbox>
                <button mat-flat-button color="primary" [autofocus]="true" (click)="onConfirmPhoneNr()">
                    <span>{{ 'modals.passcode.buttons.btnSendCode' | translate}}</span>
                </button>
            </mat-dialog-actions>
        </div>

        <div *ngIf="isPassCodeSended" class="div-width passcode">
            <h2 mat-dialog-title>{{ 'modals.passcode.content.title' | translate}}</h2>
            <mat-dialog-content>
                <p>{{ 'modals.passcode.content.text3_1' | translate}}</p>
                <p>{{ 'modals.passcode.content.text3_2' | translate}}</p>

                <code-input #codeInput [initialFocusField]="0" [codeLength]="6" [isCharsCode]="false"
                    (codeChanged)="onCodeEntered($event)">
                </code-input>

                <mat-error *ngIf="errorMessage" [innerHTML]="errorMessage"></mat-error>
            </mat-dialog-content>
            <mat-dialog-actions fxLayout="column-reverse" fxLayout.gt-xs="row"
                fxLayoutAlign="space-evenly none">
                <button mat-flat-button [disabled]="requestPasscodeTimer > 0" color="secondary"
                    (click)="onRequestNewCode()">
                    <span *ngIf="requestPasscodeTimer === 0">
                        {{ 'modals.passcode.buttons.btnSendNewCode' | translate}}
                    </span>
                    <span *ngIf="requestPasscodeTimer > 0">
                        {{ ('modals.passcode.buttons.btnSendCodeTimer' | translate:{timer: requestPasscodeTimer}) }}
                    </span>
                </button>
                <button mat-flat-button color="primary" #btnSendCode [disabled]="!passcode || passcode.length < 6"
                    [autofocus]="!!passcode && passcode.length === 6" (click)="onCodeCompleted()">
                    <span>
                        {{ 'modals.passcode.buttons.btnConfirm' | translate}}
                    </span>
                </button>
            </mat-dialog-actions>
        </div>
    </mat-card-content>
</div>