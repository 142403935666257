import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule, NgZone } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions, MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { BrowserSupportModule, BrowserSupportService, IEngineData } from '@ntag-ef/browser-support';
import { ErrorHandlerModule, NtagErrorHandlerService, ON_ERROR } from '@ntag-ef/error-handler';
import { EnumTranslateLoader, EnumTranslationModule } from '@ntag-ef/finprocess-enums';
import { NotificationsModule } from '@ntag-ef/notifications';
import { NtagServiceWorkerModule } from '@ntag-ef/service-worker';
import { WaiterModule } from '@ntag-ef/waiter';
import { ComponentsModule } from '@ucba/components';
import { DATA_OBSERVABLE, SdkModule } from '@ucba/sdk';
import { BaseController } from '@ucba/sdk/controller';
import * as sdkEnums from '@ucba/sdk/enums';
import { ApplicationType } from '@ucba/sdk/enums';
import { IDataType } from '@ucba/sdk/interfaces';
import { CodeInputModule } from 'angular-code-input';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { LoggerModule } from 'ngx-logger';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-rounting.module';
import { AppComponent } from './app.component';
import { AddDebtorComponent } from './components/add-debtor/add-debtor.component';
import { BankDocumentsComponent } from './components/bank-documents/bank-documents.component';
import { ChatComponent } from './components/chat/chat.component';
import { DebtorComponent } from './components/debtor/debtor.component';
import { DeleteHouseholdComponent } from './components/delete-household/delete-household.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { EntryPointComponent } from './components/entry-point/entry-point.component';
import { FinancingMenuComponent } from './components/financing-menu/financing-menu.component';
import { FinancingplanComponent } from './components/financingplan/financingplan.component';
import { HouseholdComponent } from './components/household/household.component';
import { LogoutComponent } from './components/logout/logout.component';
import { RealestateComponent } from './components/realestate/realestate.component';
import { SelfDisclosureComponent } from './components/self-disclosure/self-disclosure.component';
import { StatusComponent } from './components/status/status.component';
import { StatusTimelineComponent } from './components/status-timeline/status-timeline.component';
import { TabNavigationComponent } from './components/tab-navigation/tab-navigation.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import * as appEnums from './enums';
import { ConsentComponent } from './modals/consent/consent.component';
import { PasscodeComponent } from './modals/passcode/passcode.component';
import { ImprintComponent } from './modules/shared/components/imprint/imprint.component';
import { SharedModule } from './modules/shared/shared.module';
import { CustomEnumTranslationLoaderService, EarlyTranslationLoaderService } from './services';
import { ApplicationState, allStates, persistStates } from './statemanagement/states';

/**
 * Supported Angular Browsers
 * https://ej2.syncfusion.com/angular/documentation/browser/ *
 */
// const chrome = 63;
// const webkit = 601;
// const safari = 9; //https://en.wikipedia.org/wiki/Safari_version_history
// const firefox = 58;

// ÜBersicht über die Browser und ihre Engine Version
// https://github.com/mdn/browser-compat-data/blob/main/browsers/chrome.json

const engiens: IEngineData[] = [
    {
        name: 'blink',
        displayName: 'Google Chrome',
        downloadLink: 'https://www.google.com/chrome/',
        lastMajorVersion: 110,
    },
    {
        name: 'blink',
        displayName: 'Microsoft Edge',
        downloadLink: 'https://www.microsoft.com/de-de/edge/download',
        lastMajorVersion: 110,
    },
    {
        name: 'webkit',
        displayName: 'Safari',
        downloadLink: 'https://www.apple.com/de/safari/',
        lastMajorVersion: 601,
    },
    {
        name: 'gecko',
        displayName: 'Mozilla Firefox',
        downloadLink: 'https://www.mozilla.org/de/firefox/new/',
        lastMajorVersion: 100,
    },
];


// um LOCALE_ID zu providen
registerLocaleData(localeDe, 'de-AT');

/**
 * Modul Klasse für AppComponent
 */
@NgModule({
    declarations: [
        AppComponent,
        EntryPointComponent,
        PasscodeComponent,
        ConsentComponent,
        WelcomeComponent,
        ChatComponent,
        FinancingMenuComponent,
        SelfDisclosureComponent,
        StatusComponent,
        TabNavigationComponent,
        DebtorComponent,
        HouseholdComponent,
        RealestateComponent,
        FinancingplanComponent,
        AddDebtorComponent,
        DocumentsComponent,
        DeleteHouseholdComponent,
        BankDocumentsComponent,
        ImprintComponent,
        LogoutComponent,
        StatusTimelineComponent,
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        CodeInputModule,
        BrowserModule,
        MatCheckboxModule,
        MatIconModule,
        MatTabsModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        MatCardModule,
        MatChipsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        MatDialogModule,
        MatButtonModule,
        MatStepperModule,
        MatListModule,
        MatRadioModule,
        MatSidenavModule,
        MatTooltipModule,
        MatExpansionModule,
        MatDividerModule,
        MatBadgeModule,
        CommonModule,
        ComponentsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        SharedModule,
        BrowserSupportModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: EarlyTranslationLoaderService,
            },
        }),
        EnumTranslationModule.forRoot({
            loader: {
                provide: EnumTranslateLoader,
                useClass: CustomEnumTranslationLoaderService,
            },
            customEnums: Object.assign({}, sdkEnums, appEnums),
        }),
        NotificationsModule.forRoot({
            defaultDialogConfig: {
                buttonAlign: 'end',
            },
            defaultToastConfig: {
                verticalPosition: 'top',
            },
        }),
        LoggerModule.forRoot({
            level: environment.logLevel,
            serverLogLevel: environment.serverLogLevel,
            serverLoggingUrl: `${environment.backendUrl}/api/Logging/`,
            serverCallsOutsideNgZone: true,
        }),
        ErrorHandlerModule.forRoot({
            contactPhone: '+49 361 26160 43',
        }),
        WaiterModule.forRoot(),
        NgxsModule.forRoot(allStates, {
            developmentMode: !environment.production,
        }),
        NgxsStoragePluginModule.forRoot({
            key: persistStates,
            storage: StorageOption.SessionStorage,
        }),
        NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
        SdkModule.forRoot({
            backendUrl: environment.backendUrl,
            applicationType: ApplicationType.CXADVISORY,
        }),
        NtagServiceWorkerModule.register({
            script: 'ngsw-worker.js',
            serviceWorkerConfig: {
                enabled: environment.checkForUpdateConfiguration.enabled,
                // Register the ServiceWorker as soon as the app is stable
                // or after 30 seconds (whichever comes first).
                registrationStrategy: 'registerWhenStable:30000',
            },
            checkForUpdates: environment.checkForUpdateConfiguration,
        }),
    ],
    providers: [
        { provide: ErrorHandler, useClass: NtagErrorHandlerService },
        {
            provide: ON_ERROR,
            useFactory: (matDlg: MatDialog, ngZone: NgZone) => () => {
                ngZone.run(() => {
                    matDlg.closeAll();
                });
            },
            deps: [MatDialog, NgZone],
        },
        {
            provide: DATA_OBSERVABLE,
            useFactory: (store: Store) => combineLatest([
                store.select(ApplicationState.isLoggedIn),
                store.select(ApplicationState.hash),
            ]).pipe(map(([isLoggendIn, hash]) => ({ isLoggendIn, token: hash } as IDataType))),
            deps: [Store],
        },
        { provide: LOCALE_ID, useValue: 'de-AT' }, // @see http://www.rfc-editor.org/rfc/bcp/bcp47.txt
        EarlyTranslationLoaderService,
        {
            provide: APP_INITIALIZER,
            useFactory: (earlyTranslationLoader: EarlyTranslationLoaderService, enumTranslationLoader: CustomEnumTranslationLoaderService, browserSupportService: BrowserSupportService) => (
                // must use full function!!!!
                async () => {
                    await earlyTranslationLoader.cache();
                    await enumTranslationLoader.cache();
                    await browserSupportService.checkBrowsersEngineCompatibility(engiens);
                }
            ),
            deps: [EarlyTranslationLoaderService, CustomEnumTranslationLoaderService, BrowserSupportService],
            multi: true,
        },
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: {
                showDelay: 800,
                hideDelay: 200,
                touchendHideDelay: 100,
                disableTooltipInteractivity: true,
            } as MatTooltipDefaultOptions,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {

    /**
     * Standard Konstruktor
     *
     * @param  {MatIconRegistry} matIconRegistry MatIconRegistry
     * @param  {DomSanitizer} domSanitizer DomSanitizer
     */
    public constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
    ) {

        BaseController.backendUrl = environment.backendUrl;

        this.matIconRegistry.addSvgIcon('cxad-person', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/Icon/Person.svg'));
        this.matIconRegistry.addSvgIcon('cxad-person-grey', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/Icon/Person_grau.svg'));
        this.matIconRegistry.addSvgIcon('cxad-household', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/Icon/Haushalt.svg'));
        this.matIconRegistry.addSvgIcon('cxad-household-grey', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/Icon/Haushalt_grau.svg'));
        this.matIconRegistry.addSvgIcon('cxad-object', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/Icon/Objekt.svg'));
        this.matIconRegistry.addSvgIcon('cxad-object-grey', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/Icon/Objekt_grau.svg'));
        this.matIconRegistry.addSvgIcon('cxad-finance-plan', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/Icon/Finanzplan.svg'));
        this.matIconRegistry.addSvgIcon('cxad-finance-plan-grey', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/Icon/Finanzplan_grau.svg'));
        this.matIconRegistry.addSvgIcon('cxad-download', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/Icon/Download.svg'));
        this.matIconRegistry.addSvgIcon('cxad-download-grey', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/Icon/Download_grau.svg'));
        this.matIconRegistry.addSvgIcon('cxad-upload', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/Icon/Upload.svg'));
        this.matIconRegistry.addSvgIcon('cxad-upload-grey', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/Icon/Upload_grau.svg'));
        this.matIconRegistry.addSvgIcon('cxad-message', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/Icon/Nachricht.svg'));
        this.matIconRegistry.addSvgIcon('cxad-message-grey', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/Icon/Nachricht_grau.svg'));
        this.matIconRegistry.addSvgIcon('cxad-status', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/Icon/status_finanzierung.svg'));
        this.matIconRegistry.addSvgIcon('bank-austria', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/Icon/bank_austria.svg'));
        this.matIconRegistry.addSvgIcon('bank-austria-mobile', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/Icon/bank_austria_mobile.svg'));
    }
}
