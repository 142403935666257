import { AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, Renderer2, SecurityContext, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { DocumentType } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { CurrencyInputType } from '@ucba/components';
import { FinancingController } from '@ucba/sdk/controller';
import { FinancingMapStatus, YesNo } from '@ucba/sdk/enums';
import { IDocumentFileItem, IHouseHoldDocumentMap, IListTuple, IProductPackages } from '@ucba/sdk/interfaces';
import { IDocumentModel, IFileModel, IFinancingMapModel, IProductPackageVM } from '@ucba/sdk/models';
import { DataService, FileService, HelperService } from '@ucba/sdk/services';
import { DocumentState, FileState, FinancingMapState, HouseholdState } from '@ucba/sdk/statemanagement/states';
import { BehaviorSubject, Subject, combineLatest, map, mergeMap, take, takeUntil } from 'rxjs';

/**
 * Komponente zum heruntergeladen von Dokumenten
 */
@Component({
    selector: 'cxad-bank-documents',
    templateUrl: './bank-documents.component.html',
    styleUrls: ['./bank-documents.component.scss'],
})
export class BankDocumentsComponent implements OnInit, OnDestroy, AfterViewChecked {
    /** Notifier wenn View verlassen wird */
    private viewLeft$ = new Subject<void>();
    public form: FormGroup | undefined;
    public array?: Array<IListTuple<string> & { iconName?: string }>;
    public yesno = YesNo;
    public inputType = CurrencyInputType.PERCENT;
    public additionalInformation: string | undefined | null;
    public currentSampleCalculationInformationFiles: IDocumentFileItem[] | undefined;
    public esisDocuments: IFileModel[] | undefined;
    public rejectDocuments: IFileModel[] | undefined;
    public householdDocuments: IHouseHoldDocumentMap[] | undefined;
    public currentStatus: FinancingMapStatus | undefined;
    public financingMapStatus = FinancingMapStatus;
    public acceptedSampleCalculationFile: IFileModel | undefined;
    public showAdditionalInfoCard = false;
    public showSelectSampleCaluclationCard = false;
    public showSampleCaluclationChoiceCard = false;
    public showSampleCaluclationDeniedCard = false;
    public showAdditionalInfoDocumentsCard = false;
    public showEsisCard = false;
    public showRejectionLetterCard = false;
    public showDocumentsNotes = false;
    public showHouseholdCard = false;
    public productPackages: IProductPackages[] = [];
    public productPackagesExists = false;

    public esisProductPackages?: IProductPackageVM;
    public esisDecisionOptions: IListTuple<boolean>[] | null | undefined;
    public esisConfrimed = true;

    //wenn value ist null dann *Alternatives Rechenbeispiel* gewehlt ist
    public userAcceptedSampleCalculationFileId: string | null | undefined;
    public userAcceptedSampleCalculationFileId$ = new BehaviorSubject<string | null | undefined>('');

    @ViewChild('scrollWrapper') public scrollWrapper?: ElementRef;
    public addShadow = false;
    private isScrollListenerAdded = false;

    /**
     * Standard Konstruktor
     *
     * @param {UntypedFormBuilder} fb UntypedFormBuilder injector
     * @param {NotificationService} notification NotificationService injector
     * @param {TranslateService} translate TranslateService injector
     * @param {Store} store Store injector
     * @param {DomSanitizer} sanitizer sanitizer injector
     * @param {Router} router Router injector
     * @param {ActivatedRoute} route Route injector
     * @param {FileService} fileService FileService injector
     * @param {FinancingController} financingController FinancingController injector
     * @param {DataService} dataService DataService injector
     * @param {Renderer2} renderer Renderer2 injector
     */
    public constructor(
        private fb: UntypedFormBuilder,
        private notification: NotificationService,
        private translate: TranslateService,
        private store: Store,
        private sanitizer: DomSanitizer,
        private router: Router,
        private route: ActivatedRoute,
        private fileService: FileService,
        private financingController: FinancingController,
        private dataService: DataService,
        private renderer: Renderer2,
    ) { }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
    }
    /**
     * Angular Hook um Werte zu initialisieren
     */
    public ngOnInit(): void {

        //RB Fix und Variable dokumente
        this.store.select(DocumentState.sampleCalculationDocumentsByFinancing)
            .pipe(
                takeUntil(this.viewLeft$),
                map(documents => documents.map(({ id, type, name }) => ({ id, type, name }))),
                mergeMap(documentMap => this.store.select(FileState.filesByDocumentIds)
                    .pipe(map(fn => ({
                        files: fn(documentMap.map(({ id }) => id)),
                        documentMap,
                    })))),
            )
            .subscribe(({ files, documentMap }) => {
                if (files.length > 0) {
                    this.array = [];
                    const productPackageDocuments = documentMap.filter(({ type, name }) => (type === DocumentType.SampleCalculation) && name?.startsWith('UCBA_ProduktPaket_'));
                    if (Array.isArray(productPackageDocuments) && productPackageDocuments.length > 0) {
                        this.productPackagesExists = true;

                        this.productPackages = productPackageDocuments.map<IProductPackages>(documentModel => ({
                            productPackagesfiles: files.filter(({ documentId }) => documentId === documentModel.id).map(({ id, name }) => ({ value: id, label: name, iconName: 'description' })),
                            name: documentModel.name?.substring(documentModel.name.indexOf('UCBA_ProduktPaket_') + 'UCBA_ProduktPaket_'.length),
                            isProposeRequest: false,
                        }));

                        this.productPackages.push({
                            productPackagesfiles: [{ value: undefined, label: this.translate.instant('components.financingTab.bankdocuments.proposeRequest'), iconName: undefined }],
                            name: this.translate.instant('components.financingTab.bankdocuments.proposeRequest'),
                            isProposeRequest: true,
                        });
                    }
                    else {
                        this.productPackagesExists = false;

                        const fixDocuments = documentMap.filter(({ type }) => type === DocumentType.SampleCalculation);
                        if (Array.isArray(fixDocuments) && fixDocuments.length > 0) {
                            let counter = 0;
                            const documentIds = fixDocuments.map(({ id }) => id);

                            for (const file of files) {
                                if (documentIds.includes(file.documentId)) {
                                    const docName = DocumentType.translate(DocumentType.SampleCalculation) ?? file.name;
                                    this.array.push({ value: file.id, label: `${docName} ${++counter}`, iconName: 'description' });
                                }
                            }
                        }

                        const varDocuments = documentMap.filter(({ type }) => type === DocumentType.SampleCalculationVariable);

                        if (Array.isArray(varDocuments) && varDocuments.length > 0) {
                            let counter = 0;
                            const documentIds = varDocuments.map(({ id }) => id);

                            for (const file of files) {
                                if (documentIds.includes(file.documentId)) {
                                    const docName = DocumentType.translate(DocumentType.SampleCalculationVariable) ?? file.name;
                                    this.array.push({ value: file.id, label: `${docName} ${++counter}`, iconName: 'description' });
                                }
                            }
                        }

                        this.array?.push({ value: undefined, label: this.translate.instant('components.financingTab.bankdocuments.proposeRequest'), iconName: undefined });
                    }

                }
            });

        //RejectionLetter Documents
        this.store.select(DocumentState.currentRejectionLetterDocument)
            .pipe(
                takeUntil(this.viewLeft$),
                map(documents => documents.map(({ id }) => id)),
                mergeMap(documentsId => this.store.select(FileState.filesByDocumentIds)
                    .pipe(map(fn => fn(documentsId)))),
            )
            .subscribe(files => {
                if (files.length > 0) {
                    this.rejectDocuments = files;
                }
            });

        combineLatest([
            this.store.select(FinancingMapState.current),
            this.store.select(DocumentState.sampleCalculationInformationDocumentsByFinancing),
            this.store.select(FileState.filesByDocumentIds),
            this.store.select(HouseholdState.housholdDocumentMaps),
        ])
            .pipe(
                takeUntil(this.viewLeft$),
            )
            // eslint-disable-next-line complexity
            .subscribe(([financingMap, documents, filesByDocumentIds, maps]) => {
                const documentIds = documents.map(({ id }) => id);
                this.householdDocuments = maps;

                this.currentSampleCalculationInformationFiles = filesByDocumentIds(documentIds).map(file => {
                    const document = documents.find(({ id }) => id === file.documentId) as IDocumentModel;
                    return {
                        name: DocumentType.translate(document.type) ?? file.name,
                        documentType: document.type,
                        documentId: document.id,
                        documentName: file.name,
                        fileId: file.id,
                    }
                });

                if (!!financingMap) {

                    // wenn es ausgewähltes Dokument Id gibt, Datei finden und zeigen
                    const acceptedSampleCalculationFileId = financingMap.acceptedSampleCalculationFileId;
                    if (!!acceptedSampleCalculationFileId) {
                        const file = this.store.selectSnapshot(FileState.currentById)(acceptedSampleCalculationFileId);
                        this.acceptedSampleCalculationFile = file;
                    }

                    if (!!financingMap.additionalSampleCalculationInformation) {
                        this.additionalInformation = this.sanitizer.sanitize(SecurityContext.HTML, financingMap.additionalSampleCalculationInformation)
                    }
                    this.form = this.fb.group({
                        acceptedSampleCalculationFileId: [''],
                        sampleCalculationResponse: [financingMap.sampleCalculationResponse],
                        deniedEsisResponse: [financingMap.esisMessage],
                    }, { updateOn: 'blur' });

                    this.currentStatus = financingMap.status;
                    this.updateCardVisibility(financingMap);
                }
            });

        this.dataService.productPackageVM$
            .pipe(
                takeUntil(this.viewLeft$),
            )
            .subscribe(productPackage => {
                this.esisProductPackages = productPackage;
            });

        this.esisDecisionOptions = [
            { value: true, label: this.translate.instant('components.financingTab.bankdocuments.submitEsis') },
            { value: false, label: this.translate.instant('components.financingTab.bankdocuments.deniedEsisOption') }, // should be neues Esis anfordern
        ];
    }

    /**
     * Angular Hook nachdem die View geladen wurde
     */
    public ngAfterViewChecked() {
        if (this.scrollWrapper && !this.isScrollListenerAdded) {
            this.renderer.listen(this.scrollWrapper.nativeElement, 'scroll', () => {
                if (this.scrollWrapper?.nativeElement.scrollTop > 0) {
                    this.addShadow = true;
                } else {
                    this.addShadow = false;
                }
            });
            this.isScrollListenerAdded = true;
        }
    }


    /**
     * Entscheidung über das Rechenbeispiel senden
     *
     * @param {boolean} accepted  gibt false zurück, beim Alternativ Auswahl im Falle lediglich RB, oder wenn Alternative Button im Falle von Produktpaketen geklickt wird. Ansonstens true beim Bestätigen
     * @param {string} sampleCalculationResponse Anmerkung text
     */
    public sendCreditOfferDecision(accepted?: boolean, sampleCalculationResponse?: string): void {
        if (!!this.userAcceptedSampleCalculationFileId && accepted) {
            // Rechenbeispiel ausgewählt

            const missingFiles = [
                ...this.store.selectSnapshot(FinancingMapState.missingRequiredDocuments),
                ...this.store.selectSnapshot(DocumentState.missingHouseholdDocuments),
            ];

            if (missingFiles.length > 0) {
                this.notification.confirmOkCancel(
                    this.translate.instant('components.financingTab.bankdocuments.missingFilesTitle'),
                    this.translate.instant('components.financingTab.bankdocuments.missingFilesText'),
                ).pipe(take(1))
                    .subscribe(role => {
                        if (role === 'submit') {
                            this.router.navigate(['../documents'], { relativeTo: this.route }).catch(e => { throw e; });
                        }
                    });
            }
            else {
                this.proceedCreditOfferDecision(true, sampleCalculationResponse);
            }
        }
        else {
            // Alternative Rechenbeispiel ausgewählt
            this.proceedCreditOfferDecision(false, sampleCalculationResponse);
        }
    }

    /**
     * Entscheidung über das Esis senden
     *
     * @param {boolean} confirm Esis bestätigen
     */
    public sendEsisDecision(confirm: boolean): void {
        const titleString = `components.financingTab.bankdocuments.${confirm ? 'submitEsis' : 'deniedEsis'}`;
        const textString = `components.financingTab.bankdocuments.${confirm ? 'submitEsisText' : 'deniedEsisText'}`;

        this.notification.confirmYesNo(
            this.translate.instant(titleString),
            this.translate.instant(textString),
        )
            .pipe(take(1))
            .subscribe(role => {
                if (role === 'submit') {
                    const financingId = this.store.selectSnapshot(FinancingMapState.currentId);
                    if (!!financingId && !!this.form && this.form.valid) {
                        const formdata = this.form.value;

                        this.financingController.sendEsisDecision(
                            financingId,
                            confirm,
                            HelperService.getValueOrUndefiend(formdata.deniedEsisResponse),
                        )
                            .pipe(take(1))
                            .subscribe(status => this.dataService.handleStatusResponse(status).catch(e => { throw e; }));
                    }
                }
            });
    }

    /**
     * öffnen ein Dokument anhand einer FileId
     *
     * @param {string} id Id des Files
     * @param {string} title Name der Datei
     */
    public onOpenFile(id?: string, title?: string): void {
        if (!!id) {
            const file = this.store.selectSnapshot(FileState.currentById)(id)

            if (!!file) {
                this.fileService.showFile(file.id, file.name, file.mimeType, title).catch(e => { throw e })
            }
        }
    }

    /**
     * öffnen ein Dokument anhand einer DocumentId
     *
     * @param {string} documentId Id des Dokuments
     */
    public openDocument(documentId: string): void {
        const file = this.store.selectSnapshot(FileState.filesByDocumentIds)([documentId])[0];
        if (!!file) {
            this.fileService.showFile(file.id, file.name, file.mimeType).catch(e => { throw e });
        }
    }

    /**
     * ausgewählter DocumentId (value) kommt aus Select-Document component
     * wenn DocumentId === null, dann ist Alternatives Rechenbeispiel ausgewählt
     *
     * @param {string} id DocumentId
     */
    public onSelectedValueChanged(id: string | null): void {
        this.userAcceptedSampleCalculationFileId = id;
        this.userAcceptedSampleCalculationFileId$.next(this.userAcceptedSampleCalculationFileId);
    }

    /**
     * Fortsetzen der Rechenbeispielentscheidung nachdem alle Pflichtdokumente hochgeladen wurden
     *
     * @param {boolean} accepted wurde ein Rechenbeispiel ausgewählt
     * @param {string} sampleCalculationResponse Anmerkung text
     */
    private proceedCreditOfferDecision(accepted: boolean, sampleCalculationResponse?: string): void {
        const titleString = `components.financingTab.bankdocuments.${accepted ? 'submitCalculation' : 'proposeRequest'}`;
        const textString = `components.financingTab.bankdocuments.${accepted ? 'submitCalculationText' : 'proposeRequestText'}`;

        this.notification.confirmYesNo(
            this.translate.instant(titleString),
            this.translate.instant(textString),
        )
            .pipe(take(1))
            .subscribe(role => {
                if (role === 'submit') {
                    const financingId = this.store.selectSnapshot(FinancingMapState.currentId);
                    if (!!financingId) {

                        this.financingController.sendCreditOfferDecision(
                            financingId,
                            sampleCalculationResponse,
                            accepted ? HelperService.getValueOrUndefiend(this.userAcceptedSampleCalculationFileId) : undefined,
                        )
                            .pipe(take(1))
                            .subscribe(status => this.dataService.handleStatusResponse(status).catch(e => { throw e; }));
                        // to make sure the choice is reset when switching from product packages to normal if the bank component is still open
                        this.userAcceptedSampleCalculationFileId = undefined;
                    }
                }
            });
    }

    /**
     * aktuallsiert die Sichtbarkeit der Karten anhand des aktuellen Finanzierungsstatus
     * 
     * @param {IFinancingMapModel} financingMap die aktuelle finanzierung
     */
    // eslint-disable-next-line complexity
    private updateCardVisibility(financingMap: IFinancingMapModel) {

        switch (this.currentStatus) {
            case FinancingMapStatus.Open:
                this.showDocumentsNotes = false;
                this.showAdditionalInfoCard = false;
                this.showSelectSampleCaluclationCard = false;
                this.showSampleCaluclationChoiceCard = false;
                this.showSampleCaluclationDeniedCard = false;
                this.showAdditionalInfoDocumentsCard = false;
                this.showEsisCard = false;
                this.showRejectionLetterCard = false;
                break;

            case FinancingMapStatus.SampleCalculationRequested:
                this.showAdditionalInfoCard = !HelperService.isNullOrEmpty(this.additionalInformation);
                this.showSampleCaluclationDeniedCard = !HelperService.isNullOrEmpty(financingMap.sampleCalculationResponse);
                this.showDocumentsNotes = (!Array.isArray(this.householdDocuments) || this.householdDocuments.length === 0) && !this.showAdditionalInfoCard && !this.showSampleCaluclationDeniedCard;
                this.showSelectSampleCaluclationCard = false;
                this.showSampleCaluclationChoiceCard = false;
                this.showAdditionalInfoDocumentsCard = false;
                this.showEsisCard = false;
                this.showRejectionLetterCard = false;
                this.showHouseholdCard = Array.isArray(this.householdDocuments) && this.householdDocuments.length > 0;
                break;

            case FinancingMapStatus.SampleCalculationExists:
                this.showDocumentsNotes = false;
                this.showAdditionalInfoCard = true;
                this.showSelectSampleCaluclationCard = true;
                this.showSampleCaluclationChoiceCard = false;
                this.showSampleCaluclationDeniedCard = false;
                this.showAdditionalInfoDocumentsCard = Array.isArray(this.currentSampleCalculationInformationFiles) &&
                    this.currentSampleCalculationInformationFiles.length > 0;
                this.showEsisCard = false;
                this.showRejectionLetterCard = false;
                // Abwärtskompatibilität
                this.showHouseholdCard = true;
                break;

            case FinancingMapStatus.EsisRequested:
                this.showDocumentsNotes = false;
                this.showAdditionalInfoCard = true;
                this.showSelectSampleCaluclationCard = false;
                this.showSampleCaluclationChoiceCard = true;
                this.showSampleCaluclationDeniedCard = false;
                this.showAdditionalInfoDocumentsCard = false;
                this.showEsisCard = false;
                this.showRejectionLetterCard = false;
                this.showHouseholdCard = false;
                break;

            case FinancingMapStatus.EsisExists:
                this.showDocumentsNotes = false;
                this.showAdditionalInfoCard = true;
                this.showSelectSampleCaluclationCard = false;
                this.showSampleCaluclationChoiceCard = true;
                this.showSampleCaluclationDeniedCard = false;
                this.showAdditionalInfoDocumentsCard = false;
                this.showEsisCard = true;
                this.showRejectionLetterCard = false;
                this.showHouseholdCard = false;
                break;

            case FinancingMapStatus.VpcCompleted:
                this.showDocumentsNotes = false;
                this.showAdditionalInfoCard = true;
                this.showSelectSampleCaluclationCard = false;
                this.showSampleCaluclationChoiceCard = true;
                this.showSampleCaluclationDeniedCard = false;
                this.showAdditionalInfoDocumentsCard = false;
                this.showEsisCard = true;
                this.showRejectionLetterCard = false;
                this.showHouseholdCard = false;
                break;

            case FinancingMapStatus.VpcCanceled:
                this.showDocumentsNotes = false;
                this.showAdditionalInfoCard = !HelperService.isNullOrEmpty(this.additionalInformation);
                this.showSelectSampleCaluclationCard = false;
                this.showSampleCaluclationChoiceCard = !!this.array && this.array?.length > 0;
                this.showSampleCaluclationDeniedCard = false;
                this.showAdditionalInfoDocumentsCard = false;
                this.showEsisCard = true;
                this.showRejectionLetterCard = false;
                this.showHouseholdCard = false;
                break;

            case FinancingMapStatus.VpcRejected:
                this.showDocumentsNotes = false;
                this.showAdditionalInfoCard = !HelperService.isNullOrEmpty(this.additionalInformation);
                this.showSelectSampleCaluclationCard = false;
                this.showSampleCaluclationChoiceCard = false;
                this.showSampleCaluclationDeniedCard = false;
                this.showAdditionalInfoDocumentsCard = false;
                this.showEsisCard = false;
                this.showRejectionLetterCard = true;
                this.showHouseholdCard = false;
                break;


            case FinancingMapStatus.HouseholdCalculationRequested:
                this.showDocumentsNotes = (!Array.isArray(this.householdDocuments) || this.householdDocuments.length === 0) && !this.showAdditionalInfoCard;
                this.showAdditionalInfoCard = false;
                this.showSampleCaluclationDeniedCard = false;
                this.showSelectSampleCaluclationCard = false;
                this.showSampleCaluclationChoiceCard = false;
                this.showAdditionalInfoDocumentsCard = false;
                this.showEsisCard = false;
                this.showRejectionLetterCard = false;
                this.showHouseholdCard = false;
                break;

            case FinancingMapStatus.HouseholdCalculationExist:
                this.showDocumentsNotes = false;
                this.showAdditionalInfoCard = false;
                this.showSelectSampleCaluclationCard = false;
                this.showSampleCaluclationChoiceCard = false;
                this.showSampleCaluclationDeniedCard = false;
                this.showAdditionalInfoDocumentsCard = false;
                this.showEsisCard = false;
                this.showRejectionLetterCard = false;
                this.showHouseholdCard = true;
                break;

            default: break;
        }
    }

    /**
     *beurteilt, ob die Karte angezeigt werden soll
     *
     * @returns {boolean} true oder false
     */
    public showAdditionalInfo(): boolean {
        return !!this.additionalInformation && this.showAdditionalInfoCard;
    }
}
