<waiter-component [isLoading]="!form" class="area-wrapper area-height">
    <span class="area-title">{{ 'components.financingTab.realEstate.title' | translate }}</span>
    <cxad-read-only [isVisible]="validation.isReadOnly"></cxad-read-only>
    <mat-chip-listbox class="shadow-bottom" [class.apply-shadow]="addShadow">
        <mat-chip-option color="primary" [selected]="selectedRealestateIndex === 0" (click)="onSelectRealestate(0)">{{
            'components.financingTab.realEstate.titleFinancingObject' | translate }}</mat-chip-option>
        <mat-chip-option *ngFor="let realestate of realestates | slice:1; let i = index" removable
            [selected]="selectedRealestateIndex === i + 1" (removed)="removeRealestate(realestate)"
            (click)="onSelectRealestate(i + 1)">
            {{ realEstateLabels[i + 1] }}
            <mat-icon *ngIf="!validation.isReadOnly"
                [matTooltip]="'components.financingTab.realEstate.removeRealestateTitle' | translate"
                matChipRemove>close</mat-icon>
        </mat-chip-option>
        <button mat-flat-button *ngIf="realestates.length < maxRealEstates" (click)="addRealestate()"
            [disabled]="validation.isReadOnly" type="button" color="primary">
            {{ 'components.financingTab.realEstate.addNewRealEstateButton' | translate }}
        </button>
    </mat-chip-listbox>
    <form *ngIf="form" class="scroll-wrapper" #scrollWrapper>
        <div fxLayout="column" *ngIf="visibleMap">
            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px"
                class="realestate-title-container">
                <h2 class="realestate-title" *ngIf="selectedRealestateIndex === 0;else real_estate_title">
                    <!-- Objekt zukünftige Situation -->
                    {{ 'components.financingTab.realEstate.titleObjectData' | translate }}
                </h2>
                <ng-template #real_estate_title>
                    <div fxFlex fxFlex.gt-sm="1 1 0px" fxLayout="row" fxLayoutAlign="start center">
                        <h2 class="realestate-title base-label">
                            <ng-container *ngIf="!isEditingTitle">
                                {{ realEstateLabels[selectedRealestateIndex] }}
                            </ng-container>
                            <ng-container *ngIf="isEditingTitle">
                                {{ 'components.financingTab.realEstate.labelRealEstate' |
                                translate:{i:selectedRealestateIndex + 1} }}
                            </ng-container>
                        </h2>
                        <cxad-input *ngIf="isEditingTitle" class="label-input" [control]="form.controls['name']"
                            (blurred)="onEditRealestateTitle()" [isFocused]="true">
                        </cxad-input>
                        <button *ngIf="!isEditingTitle" mat-icon-button type="button" (click)="isEditingTitle = true"
                            [disabled]="validation.isReadOnly"
                            [matTooltip]="'components.financingTab.realEstate.editRealestateNameTooltip' | translate">
                            <mat-icon>create</mat-icon>
                        </button>
                    </div>
                </ng-template>
                <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
            </div>
            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                <cxad-select fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['type']" [enum]="realEstateType"
                    translationKey="RealEstateType">
                </cxad-select>

                <cxad-select *ngIf="visibleMap.fieldObjectType" fxFlex fxFlex.gt-sm="1 1 0px" [allowNoSelection]="false"
                    [control]="form.controls['objectPurpose']" [enum]="objectPurposeType"
                    [customLabel]="'components.financingTab.realEstate.realestatePurpose' | translate"
                    translationKey="ObjectPurposeType">
                </cxad-select>

                <cxad-select *ngIf="visibleMap.fieldWillBeRentedOut" fxFlex fxFlex.gt-sm="1 1 0px"
                    [control]="form.controls['willBeRentedOut']" [enum]="yesno"
                    [customLabel]="'components.financingTab.realEstate.willBeRentedOut' | translate"
                    translationKey="YesNo">
                </cxad-select>
                <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
            </div>
            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                <cxad-input fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['propertyRegisterNumber']"
                    [labelText]="'components.financingTab.realEstate.propertyRegisterNumber' | translate"
                    [comment]="'components.financingTab.realEstate.landRegisterExtractComment' | translate">
                </cxad-input>
                <cxad-input fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['landRegisterNumber']"
                    [labelText]="'components.financingTab.realEstate.landRegisterNumber' | translate"
                    [comment]="'components.financingTab.realEstate.landRegisterExtractComment' | translate">
                </cxad-input>
                <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
            </div>
            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px"
                *ngIf="visibleMap.cardOtherObjectSpecifications">
                <cxad-input fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['lotNumber']"
                    [labelText]="'components.financingTab.realEstate.lotNumber' | translate"
                    [comment]="'components.financingTab.realEstate.landRegisterExtractComment' | translate">
                </cxad-input>
                <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
            </div>

            <!-- Grundstücksadresse -->
            <h2>{{ 'components.financingTab.realEstate.titlePropertyAddress' | translate }}</h2>
            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                <cxad-input fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['street']"
                    [labelText]="'components.financingTab.realEstate.street' | translate">
                </cxad-input>
                <cxad-input fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['streetNumber']"
                    [labelText]="'components.financingTab.realEstate.streetNumber' | translate">
                </cxad-input>
                <cxad-input fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['zip']"
                    [labelText]="'components.financingTab.realEstate.zip' | translate">
                </cxad-input>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between end" fxLayoutGap="10px">
                <cxad-input fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['city']"
                    [labelText]="'components.financingTab.realEstate.city' | translate">
                </cxad-input>
                <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
            </div>

            <!-- Weitere Objektangaben -->
            <ng-container *ngIf="visibleMap.cardOtherObjectSpecifications">
                <h2>{{ 'components.financingTab.realEstate.titleOtherObjectData' | translate }}</h2>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                    <cxad-input-currency fxFlex fxFlex.gt-sm="1 1 0px" [type]="currencyInputType.AREA"
                        [control]="form.controls['lotSize']" *ngIf="visibleMap.fieldLotSize"
                        [labelText]="'components.financingTab.realEstate.lotSize' | translate">
                    </cxad-input-currency>
                    <cxad-input fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['floor']" type="number"
                        *ngIf="visibleMap.fieldFloor"
                        [labelText]="'components.financingTab.realEstate.floor' | translate">
                    </cxad-input>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                </div>
            </ng-container>

            <!-- Zusatz Adresse  -->
            <ng-container *ngIf="visibleMap.cardAdditionalAddress">
                <h2>{{ 'components.financingTab.realEstate.titleAdditionalAddress' | translate }}</h2>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                    <cxad-input fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['stairway']"
                        [labelText]="'components.financingTab.realEstate.stairway' | translate">
                    </cxad-input>
                    <cxad-input fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['top']"
                        [labelText]="'components.financingTab.realEstate.top' | translate">
                    </cxad-input>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                </div>
            </ng-container>

            <!-- Baujahr -->
            <ng-container *ngIf="visibleMap.cardYearOfConstruction">
                <h2>{{ 'components.financingTab.realEstate.titleYearOfConstruction' | translate }}</h2>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                    <cxad-select fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['underConstruction']"
                        [enum]="yesno"
                        [customLabel]="'components.financingTab.realEstate.underConstruction' | translate"
                        translationKey="YesNo">
                    </cxad-select>
                    <cxad-input fxFlex fxFlex.gt-sm="1 1 0px" type="year"
                        [control]="form.controls['yearOfConstruction']" *ngIf="visibleMap.fieldYearOfConstruction"
                        [labelText]="'components.financingTab.realEstate.yearOfConstruction' | translate">
                    </cxad-input>
                    <cxad-date fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['startOfConstruction']"
                        *ngIf="visibleMap.fieldStartOfConstruction"
                        [labelText]="'components.financingTab.realEstate.startOfConstruction' | translate">
                    </cxad-date>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px" *ngIf="!visibleMap.fieldYearOfConstruction"></span>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px" *ngIf="!visibleMap.fieldStartOfConstruction"></span>
                </div>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                    <cxad-date fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['endOfConstruction']"
                        *ngIf="visibleMap.fieldEndOfConstruction"
                        [labelText]="'components.financingTab.realEstate.endOfConstruction' | translate">
                    </cxad-date>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px" *ngIf="!visibleMap.fieldEndOfConstruction"></span>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                </div>
            </ng-container>

            <!-- Ergänzende Objektangaben -->
            <ng-container *ngIf="visibleMap.cardAdditionalInformation">
                <h2>{{ 'components.financingTab.realEstate.titleAdditionalInformation' | translate }}</h2>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                    <cxad-select fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['prefabricatedConstruction']"
                        [enum]="yesno" translationKey="YesNo"
                        [customLabel]="'components.financingTab.realEstate.prefabricatedConstruction' | translate">
                    </cxad-select>
                    <cxad-select fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['buildingMaterialType']"
                        [enum]="buildingMaterialType" translationKey="BuildingMaterialType">
                    </cxad-select>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                </div>
            </ng-container>

            <!-- Flächen -->
            <ng-container *ngIf="visibleMap.cardAreas">
                <h2>{{ 'components.financingTab.realEstate.titleAreas' | translate }}</h2>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                    <cxad-input-currency fxFlex fxFlex.gt-sm="1 1 0px" *ngIf="visibleMap.fieldNetRoomArea"
                        [type]="currencyInputType.AREA" [control]="form.controls['netRoomArea']"
                        [labelText]="'components.financingTab.realEstate.netRoomArea' | translate">
                    </cxad-input-currency>
                    <cxad-input-currency fxFlex fxFlex.gt-sm="1 1 0px" [type]="currencyInputType.AREA"
                        [control]="form.controls['areaBasement']"
                        [labelText]="'components.financingTab.realEstate.areaBasement' | translate">
                    </cxad-input-currency>
                    <cxad-input-currency fxFlex fxFlex.gt-sm="1 1 0px" *ngIf="visibleMap.fieldAreaGroundFloor"
                        [type]="currencyInputType.AREA" [control]="form.controls['areaGroundFloor']"
                        [labelText]="'components.financingTab.realEstate.areaGroundFloor' | translate">
                    </cxad-input-currency>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                </div>
            </ng-container>

            <!-- Flächen weitere Geschosse -->
            <ng-container *ngIf="visibleMap.cardOtherFloors">
                <h2>{{ 'components.financingTab.realEstate.titleOtherFloors' | translate }}</h2>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                    <cxad-input-currency fxFlex fxFlex.gt-sm="1 1 0px" [type]="currencyInputType.AREA"
                        [control]="form.controls['areaUpperFloor']"
                        [labelText]="'components.financingTab.realEstate.areaUpperFloor' | translate">
                    </cxad-input-currency>
                    <cxad-input-currency fxFlex fxFlex.gt-sm="1 1 0px" [type]="currencyInputType.AREA"
                        [control]="form.controls['areaAdditionalUpperFloor']"
                        [labelText]="'components.financingTab.realEstate.areaAdditionalUpperFloor' | translate">
                    </cxad-input-currency>
                    <cxad-input-currency fxFlex fxFlex.gt-sm="1 1 0px" [type]="currencyInputType.AREA"
                        [control]="form.controls['areaTopFloor']"
                        [labelText]="'components.financingTab.realEstate.areaTopFloor' | translate">
                    </cxad-input-currency>
                </div>
            </ng-container>


            <!-- Sonstige Flächen -->
            <ng-container *ngIf="visibleMap.cardOtherAreas1">
                <h2>{{ 'components.financingTab.realEstate.titleOtherAreas' | translate }}</h2>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                    <cxad-input-currency fxFlex fxFlex.gt-sm="1 1 0px" [type]="currencyInputType.AREA"
                        [control]="form.controls['areaLoggia']"
                        [labelText]="'components.financingTab.realEstate.areaLoggia' | translate">
                    </cxad-input-currency>
                    <cxad-input-currency fxFlex fxFlex.gt-sm="1 1 0px" *ngIf="visibleMap.fieldAreaRoofTerrace"
                        [type]="currencyInputType.AREA" [control]="form.controls['areaRoofTerrace']"
                        [labelText]="'components.financingTab.realEstate.areaRoofTerrace' | translate">
                    </cxad-input-currency>
                    <cxad-input-currency fxFlex fxFlex.gt-sm="1 1 0px" [type]="currencyInputType.AREA"
                        [control]="form.controls['areaBalcony']"
                        [labelText]="'components.financingTab.realEstate.areaBalcony' | translate">
                    </cxad-input-currency>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px" *ngIf="!visibleMap.fieldAreaRoofTerrace"></span>
                </div>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                    <cxad-input-currency fxFlex fxFlex.gt-sm="1 1 0px" *ngIf="visibleMap.fieldAreaGarden"
                        [type]="currencyInputType.AREA" [control]="form.controls['areaGarden']"
                        [labelText]="'components.financingTab.realEstate.areaGarden' | translate">
                    </cxad-input-currency>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                </div>
            </ng-container>

            <!-- Sonstige Flächen -->
            <ng-container *ngIf="visibleMap.cardOtherAreas2">
                <h2>{{ 'components.financingTab.realEstate.titleOtherAreas' | translate }}</h2>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                    <cxad-input-currency fxFlex fxFlex.gt-sm="1 1 0px" [type]="currencyInputType.AREA"
                        [control]="form.controls['areaTerrace']"
                        [labelText]="'components.financingTab.realEstate.areaTerrace' | translate">
                    </cxad-input-currency>
                    <cxad-input-currency fxFlex fxFlex.gt-sm="1 1 0px" [type]="currencyInputType.AREA"
                        [control]="form.controls['areaWinterGarden']"
                        [labelText]="'components.financingTab.realEstate.areaWinterGarden' | translate">
                    </cxad-input-currency>
                    <cxad-input-currency fxFlex fxFlex.gt-sm="1 1 0px" [type]="currencyInputType.AREA"
                        [control]="form.controls['areaGarage']"
                        [labelText]="'components.financingTab.realEstate.areaGarage' | translate">
                    </cxad-input-currency>
                </div>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                    <cxad-input-currency fxFlex fxFlex.gt-sm="1 1 0px" [type]="currencyInputType.AREA"
                        [control]="form.controls['areaOutbuildings']"
                        [labelText]="'components.financingTab.realEstate.areaOutbuildings' | translate">
                    </cxad-input-currency>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                </div>
            </ng-container>

            <!-- Keller -->
            <ng-container *ngIf="visibleMap.cardBasement">
                <h2>{{ 'components.financingTab.realEstate.titleBasementUsage' | translate }}</h2>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                    <cxad-select fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['basementUsage']"
                        [enum]="basementUsage" translationKey="BasementUsage">
                    </cxad-select>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                </div>
            </ng-container>

            <ng-container *ngIf="visibleMap.cardBasement">
                <cxad-select-flat [control]="form.controls['basementConstruction']" [enum]="basementConstruction"
                    translationKey="BasementConstruction"></cxad-select-flat>
            </ng-container>

            <!-- Dachgeschoss -->
            <ng-container *ngIf="visibleMap.cardRoofConstruction">
                <h2>{{ 'components.financingTab.realEstate.titleRoofConstruction' | translate }}</h2>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                    <cxad-select fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['roofConstruction']"
                        [enum]="roofConstruction" translationKey="RoofConstruction">
                    </cxad-select>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                </div>
            </ng-container>

            <!-- Nutzbarkeit/Lage -->
            <ng-container *ngIf="visibleMap.cardUsabilityLocation">
                <h2>{{ 'components.financingTab.realEstate.titleUsabilityLocation' | translate }}</h2>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                    <cxad-select fxFlex fxFlex.gt-sm="1 1 0px" *ngIf="visibleMap.fieldTerrace"
                        [control]="form.controls['terrace']" [enum]="terrace" translationKey="Terrace">
                    </cxad-select>
                    <cxad-select fxFlex fxFlex.gt-sm="1 1 0px" *ngIf="visibleMap.fieldWintergardenType"
                        [control]="form.controls['wintergardenType']" [enum]="wintergardenType"
                        translationKey="WintergardenType">
                    </cxad-select>
                    <cxad-select fxFlex fxFlex.gt-sm="1 1 0px" *ngIf="visibleMap.fieldAnnexType"
                        [control]="form.controls['annexType']" [enum]="annexType" translationKey="AnnexType">
                    </cxad-select>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px" *ngIf="!visibleMap.fieldTerrace"></span>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px" *ngIf="!visibleMap.fieldWintergardenType"></span>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px" *ngIf="!visibleMap.fieldAnnexType"></span>
                </div>
            </ng-container>

            <!-- Garage -->
            <ng-container *ngIf="visibleMap.cardGarage">
                <h2>{{ 'components.financingTab.realEstate.titleParkingSpace' | translate }}</h2>
                <cxad-select-flat *ngIf="visibleMap.fieldParkingSpace" [control]="form.controls['parkingSpace']"
                    [enum]="parkingSpace" translationKey="ParkingSpace"></cxad-select-flat>
                <cxad-select-flat [control]="form.controls['garageFacilities']" [enum]="garageFacilities"
                    translationKey="GarageFacilities"></cxad-select-flat>
            </ng-container>

            <!-- Außensanierung - Sanierungsjahr-->
            <ng-container *ngIf="visibleMap.cardOuterBuildingRefurbishment">
                <h2>{{ 'components.financingTab.realEstate.titleOuterRefurbishment' | translate }}</h2>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                    <cxad-input fxFlex fxFlex.gt-sm="1 1 0px"
                        [control]="form.controls['refurbishmentInnerBuildungStructure']" type="year"
                        [labelText]="'components.financingTab.realEstate.refurbishmentInnerBuildungStructure' | translate"
                        [comment]="'components.financingTab.realEstate.refurbishmentInnerBuildungStructureComment' | translate">
                    </cxad-input>
                    <cxad-input fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['refurbishmentRoof']" type="year"
                        [labelText]="'components.financingTab.realEstate.refurbishmentRoof' | translate"
                        [comment]="'components.financingTab.realEstate.refurbishmentRoofComment' | translate">
                    </cxad-input>
                    <cxad-input fxFlex fxFlex.gt-sm="1 1 0px"
                        [control]="form.controls['refurbishmentOuterBuildingStructure']" type="year"
                        [labelText]="'components.financingTab.realEstate.refurbishmentOuterBuildingStructure' | translate"
                        [comment]="'components.financingTab.realEstate.refurbishmentOuterBuildingStructureComment' | translate">
                    </cxad-input>
                </div>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                    <cxad-input fxFlex fxFlex.gt-sm="1 1 0px" *ngIf="!visibleMap.isTypeDhOrRhOrSdhOrAhOrO"
                        [control]="form.controls['refurbishmentCommonArea']" type="year"
                        [labelText]="'components.financingTab.realEstate.refurbishmentCommonArea' | translate"
                        [comment]="'components.financingTab.realEstate.refurbishmentCommonAreaComment' | translate">
                    </cxad-input>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                </div>
            </ng-container>

            <!-- Innensanierung - Sanierungsjahr -->
            <ng-container *ngIf="visibleMap.cardInnerBuildingRefurbishment">
                <h2>{{ 'components.financingTab.realEstate.titleInnerRefurbishment' | translate }}</h2>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                    <cxad-input fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['refurbishmentWindow']"
                        type="year" [labelText]="'components.financingTab.realEstate.refurbishmentWindow' | translate">
                    </cxad-input>
                    <cxad-input fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['refurbishmentHeating']"
                        type="year" [labelText]="'components.financingTab.realEstate.refurbishmentHeating' | translate"
                        [comment]="'components.financingTab.realEstate.refurbishmentHeatingComment' | translate">
                    </cxad-input>
                    <cxad-input fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['refurbishmentElectricSanitary']"
                        type="year"
                        [labelText]="'components.financingTab.realEstate.refurbishmentElectricSanitary' | translate">
                    </cxad-input>
                </div>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                    <cxad-input fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['refurbishmentWallpapering']"
                        type="year"
                        [labelText]="'components.financingTab.realEstate.refurbishmentWallpapering' | translate">
                    </cxad-input>
                    <cxad-input fxFlex fxFlex.gt-sm="1 1 0px"
                        [control]="form.controls['refurbishmentInnerDoorsStairsFloorCovering']" type="year"
                        [labelText]="'components.financingTab.realEstate.refurbishmentInnerDoorsStairsFloorCovering' | translate">
                    </cxad-input>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                </div>
            </ng-container>

            <!-- Orientierung  -->
            <ng-container *ngIf="visibleMap.cardOrientation">
                <h2>{{ 'components.financingTab.realEstate.titleOrientation' | translate }}</h2>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                    <cxad-select fxFlex fxFlex.gt-sm="1 1 0px" *ngIf="visibleMap.fieldLivingRoomOrientation"
                        [control]="form.controls['livingRoomOrientation']" [enum]="livingRoomOrientation"
                        translationKey="Orientation"
                        [customLabel]="'components.financingTab.realEstate.livingRoomOrientation' | translate"
                        [comment]="'components.financingTab.realEstate.livingRoomOrientationComment' | translate">
                    </cxad-select>
                    <cxad-select fxFlex fxFlex.gt-sm="1 1 0px" *ngIf="visibleMap.fieldFlatOrientation"
                        [control]="form.controls['flatOrientation']" [enum]="flatOrientation"
                        translationKey="FlatOrientation"
                        [comment]="'components.financingTab.realEstate.flatOrientationComment' | translate">
                    </cxad-select>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px" *ngIf="!visibleMap.fieldLivingRoomOrientation"></span>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px" *ngIf="!visibleMap.fieldFlatOrientation"></span>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                </div>
            </ng-container>

            <!-- Ausstattung Badezimmer -->
            <ng-container *ngIf="visibleMap.cardMainBathroomFacilities">
                <h2>{{ 'components.financingTab.realEstate.titleMainBathroomFacilities' | translate }}</h2>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                    <cxad-input fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['bathroomCount']"
                        [type]="'number'" [labelText]="'components.financingTab.realEstate.bathroomCount' | translate">
                    </cxad-input>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                </div>
            </ng-container>

            <ng-container *ngIf="visibleMap.cardMainBathroomFacilities">
                <cxad-select-flat [control]="form.controls['mainBathroomFacilities']" [enum]="mainBathroomFacilities"
                    translationKey="BathroomFacilities"></cxad-select-flat>
            </ng-container>

            <!-- Heizung  -->
            <ng-container *ngIf="visibleMap.cardHeater">
                <h2>{{ 'components.financingTab.realEstate.titleHeating' | translate }}</h2>
                <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" fxLayoutGap="10px">
                    <cxad-select fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['heatingSystem']"
                        [enum]="heatingSystem" translationKey="HeatingSystem">
                    </cxad-select>
                    <cxad-select fxFlex fxFlex.gt-sm="1 1 0px" [control]="form.controls['heatingSystemType']"
                        [enum]="heatingSystemType" translationKey="HeatingSystemType">
                    </cxad-select>
                    <span fxHide fxShow.gt-sm fxFlex="1 1 0px"></span>
                </div>
            </ng-container>

            <ng-container *ngIf="visibleMap.cardHeater">
                <cxad-select-flat [control]="form.controls['additionalHeatingSystem']" [enum]="additionalHeatingSystem"
                    translationKey="AdditionalHeatingSystem"></cxad-select-flat>
            </ng-container>

            <!-- Ausstattung Außenbereich -->
            <ng-container *ngIf="visibleMap.cardOutsideFacilities">
                <h2>{{ 'components.financingTab.realEstate.titleOutsideFacilities' | translate }}</h2>
                <cxad-select-flat [control]="form.controls['outsideFacilities']" [enum]="outsideFacilities"
                    translationKey="OutsideFacilities"></cxad-select-flat>
            </ng-container>

            <!-- Weitere Ausstattungen -->
            <ng-container *ngIf="visibleMap.cardAdditionalFacilities">
                <h2>{{ 'components.financingTab.realEstate.titleAdditionalFacilities' | translate }}</h2>
                <cxad-select-flat [control]="form.controls['additionalFacilities']" [enum]="additionalFacilities"
                    translationKey="AdditionalFacilities"
                    [customLabel]="'components.financingTab.realEstate.additionalFacilities' | translate">
                </cxad-select-flat>
            </ng-container>
        </div>
    </form>
    <div fxLayout="row" fxLayoutAlign="end center" class="next-btn">
        <button mat-flat-button (click)="onNext()" type="button" color="primary">
            {{ 'global.buttons.next' | translate }}
        </button>
    </div>
</waiter-component>