<div class="alert-warning" *ngIf="isVisible">
    <div class="content">
        <span class="text">
            {{ 'global.readonly.text' | translate }} <br>
        </span>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-evenly" fxLayoutAlign.lt-md="space-between" *ngIf="status !== undefined && status !== null">
            <div fxLayout="row" fxLayoutAlign="space-between">
                <span class="label">{{ 'global.readonly.status' | translate }} &nbsp;</span>
                <span>{{ status | finprocessEnumTranslation:'FinancingMapStatus' }}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between">
                <span class="label" *ngIf="clerkDisplayName !== undefined && clerkDisplayName !== null">{{
                    'global.readonly.clerkDisplayName' | translate }}&nbsp;</span>
                <span *ngIf="clerkDisplayName !== undefined && clerkDisplayName !== null">{{ clerkDisplayName }}</span>
            </div>
        </div>
    </div>
</div>