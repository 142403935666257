import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

/**
 * Komponente, welche Textarea darstellt
 */
@Component({
    selector: 'cxad-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent implements OnInit, OnDestroy {

    @Input() public control!: AbstractControl;
    @Input() public labelText!: string;
    @Input() public comment: string | undefined;
    @Input() public dynamicRows = false;
    @Input() public rows = 1;
    @Input() public isReadOnly = false;
    public isRequired: boolean | undefined;

    private minRows = 1;

    private destroyed$ = new Subject<void>();

    /**
     * gibt den aktuellen AbstractControl als FormControl zurück
     *
     * @returns {FormControl} control
     */
    public get formControl(): FormControl {
        return this.control as FormControl;
    }

    /**
     * Angular Hook zum initialisieren
     */
    public ngOnInit(): void {
        // @see https://stackoverflow.com/a/43904237
        const validators = !!this.control && !!this.control.validator ? this.control.validator(new FormControl()) : {};
        this.isRequired = !!validators && ('required' in validators);
        this.minRows = this.rows;
        
        if (this.isReadOnly) {
            this.control.disable();
        }
    }

    /**
     * Eventhandler für Textarea-input Event
     * 
     * @param {Event} event event
     */
    public onTextareaInput(event: Event) {
        if (this.dynamicRows) {
            const input = event.target as HTMLTextAreaElement;
            const addedRows = input.value ? (input.value.match(/\n/g) || []).length + 1 : 0;
            this.rows = Math.max(addedRows, this.minRows);
        }
    }


    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.destroyed$.next();
    }

}

