import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { EnumTranslationService } from '@ntag-ef/finprocess-enums';
import { IListTuple } from '@ucba/sdk/interfaces';

/**
 * Komponente, welche eine Selekt auswahl darstellt
 */
@Component({
    selector: 'cxad-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
})
export class SelectComponent<T> implements OnInit {

    /*
        benutzung bei Enum:
        enum und translationKey setzen

        benutzung bei Array
        array und customLabel setzen

        customLabel kann immer gesetzt werden um den Feldnamen zu überschreiebn (z.B. bei speziellen Enums)
    */

    @Input() public control!: AbstractControl;
    @Input() public comment: string | undefined;

    /**
     * Setter um Enum bei jeder Änderung zu aktualisieren
     *
     * @param  {object | undefined} enumValue Enum
     */
    @Input() public set enum(enumValue: T | undefined) {
        if (!!enumValue) {
            this.setEnum(enumValue);
        }
    }

    /**
     * Setter um Array bei jeder Änderung zu aktualisieren
     */
    @Input() public set array(arrayValue: IListTuple<string>[] | null) {
        if (!!arrayValue) {
            this.selectList = arrayValue;
            this.addNoSelectionOption();
        }
    }

    @Input() public stringArray: string[] | null | undefined;
    @Input() public translationKey: string | undefined;
    @Input() public iconName?: string;
    @Input() public multiple = false;
    @Input() public allowNoSelection = true;

    /** es wird immer ein eigenes Label definiert */
    @Input() public customLabel: string | undefined;

    @Input() public isHorizontal = false;

    @Output() public selectionChange = new EventEmitter<MatSelectChange>();

    public isRequired: boolean | undefined;
    public selectList: IListTuple<T | string>[] = [];


    /**
     * gibt den aktuellen AbstractControl als FormControl zurück
     *
     * @returns {FormControl} control
     */
    public get formControl(): FormControl {
        return this.control as FormControl;
    }

    /**
     * Standard Konstruktor
     *
     * @param  {EnumTranslationService} enumTranslate EnumTranslationService injector
     * @param  {TranslateService} translate TranslateService injector
     */
    public constructor(
        private enumTranslate: EnumTranslationService,
        private translate: TranslateService,
    ) { }

    /**
     * Angular Hook zum initialisieren
     */
    public ngOnInit(): void {
        // @see https://stackoverflow.com/a/43904237
        const validators = !!this.control && !!this.control.validator ? this.control.validator(new FormControl()) : {};
        this.isRequired = !!validators && ('required' in validators);

        if (!!this.stringArray) {
            this.selectList = this.stringArray.map<IListTuple<string>>(value => ({
                value,
                label: value,
            }));
            this.addNoSelectionOption();
        }
    }

    /**
     * Initialisert oder aktualisiert den Enum
     *
     * @param {object} enumValue Enum
     */
    private setEnum(enumValue: Record<string | number, T>): void {
        this.selectList = Object.values(enumValue).reduce<IListTuple<T>[]>((acc, curr: T) => {

            if (typeof curr === 'number') {
                // "Keine" wird immer ausgeschlossen
                const label = this.enumTranslate.instant({ type: this.translationKey || '', value: curr.toString() }) as string;
                return (!label.toLowerCase().endsWith('keine') && !label.toLowerCase().endsWith('keine/r')) ? [...acc, {
                    value: curr,
                    label,
                }] : acc;
            }
            else {
                return acc;
            }
        }, []);

        this.addNoSelectionOption();
    }

    /**
     * Fügt bei Einfach-Auswahlfeldern eine Keine Auswahl Option hinzu
     */
    private addNoSelectionOption(): void {
        if (!this.multiple && this.allowNoSelection) {
            this.selectList.unshift({ value: null, label: this.translate.instant('global.noSelection') });
        }
    }
}
