<waiter-component [isLoading]="!form && !realEstateForm && !financingMapForm" class="area-wrapper area-height">
    <span class="area-title" [class.apply-shadow]="!validation.isReadOnly && addShadow"
        [class.shadow-bottom]="!validation.isReadOnly">{{
        'components.financingTab.financingplan.title' | translate}}</span>
    <cxad-read-only [class.shadow-bottom]="validation.isReadOnly" [class.apply-shadow]="validation.isReadOnly && addShadow"
        [isVisible]="validation.isReadOnly"></cxad-read-only>
    <form *ngIf="form && realEstateForm && financingMapForm" [formGroup]="form" class="scroll-wrapper" #scrollWrapper>
        <div fxLayout="column" *ngIf="(financingPlanCalculations$ | async) as calculations"
            [style.overflow-x]="'hidden'">

            <!-- Projektrahmen -->
            <cxad-select [control]="realEstateForm.controls['creditPurpose']" [isHorizontal]="true"
                [enum]="creditPurpose" translationKey="CreditPurpose">
            </cxad-select>
            <cxad-select [control]="realEstateForm.controls['type']" [isHorizontal]="true" [enum]="realEstateType"
                translationKey="RealEstateType">
            </cxad-select>
            <cxad-input type="number" [isHorizontal]="true" [control]="financingMapForm.controls['assumedDuration']"
                [labelText]="'components.financingTab.financingplan.assumedDuration' | translate">
            </cxad-input>

            <!-- Projektkosten -->
            <cxad-input-currency-h [labelText]="'components.financingTab.financingplan.purchasePrice' | translate"
                [comment]="'components.financingTab.financingplan.purchasePriceComment' | translate"
                [control]="realEstateForm.controls['purchasePrice']">
            </cxad-input-currency-h>

            <cxad-input-currency-h [labelText]="'components.financingTab.financingplan.developmentCosts' | translate"
                [control]="realEstateForm.controls['developmentCosts']">
            </cxad-input-currency-h>

            <cxad-input-currency-h [labelText]="'components.financingTab.financingplan.constructionCosts' | translate"
                [control]="realEstateForm.controls['constructionCosts']">
            </cxad-input-currency-h>

            <!-- Projektkosten 2 -->
            <cxad-input-currency-h [control]="realEstateForm.controls['refurbishmentCosts']"
                [labelText]="'components.financingTab.financingplan.refurbishmentCosts' | translate">
            </cxad-input-currency-h>
            <cxad-output-currency-h [value]="calculations.purchasingAdditionalCostsSum"
                [labelText]="'components.financingTab.financingplan.costOfPurchasing' | translate"
                [comment]="'components.financingTab.financingplan.costOfPurchasingComment' | translate">
            </cxad-output-currency-h>
            <cxad-input-currency-h [control]="realEstateForm.controls['otherCosts']"
                [labelText]="'components.financingTab.financingplan.otherCosts' | translate">
            </cxad-input-currency-h>
            <cxad-output-currency-h [value]="calculations.projectCostSum"
                [labelText]="'components.financingTab.financingplan.projectCostSum' | translate">
            </cxad-output-currency-h>

            <!-- Kaufnebenkosten -->
            <cxad-multi-input-h [labelText]="'components.financingTab.financingplan.notaryFeeInput' | translate"
                [externalForm]="asFormGroup(notaryFeeForm)" [maxPercent]="notaryFeeMaximum"
                [baseValue]="calculations.prePurchasingAdditionalCostsSum" [defaultPercent]="notaryFeeDefault">
            </cxad-multi-input-h>
            <cxad-multi-input-h [labelText]="'components.financingTab.financingplan.brokerageFeeInput' | translate"
                [externalForm]="asFormGroup(brokerageFeeForm)" [maxPercent]="brokerageFeeMaximum"
                [baseValue]="calculations.prePurchasingAdditionalCostsSum" [defaultPercent]="brokerageFeeDefault">
            </cxad-multi-input-h>

            <!-- Kaufnebenkosten -->
            <cxad-output-currency-h [value]="calculations.realEstateTransferTax"
                [labelText]="'components.financingTab.financingplan.realEstateTransferTax' | translate"
                [comment]="'components.financingTab.financingplan.realEstateTransferTaxComment' | translate">
            </cxad-output-currency-h>

            <cxad-output-currency-h [value]="calculations.rightOfOwnership"
                [labelText]="'components.financingTab.financingplan.rightOfOwnership' | translate"
                [comment]="'components.financingTab.financingplan.rightOfOwnershipComment' | translate">
            </cxad-output-currency-h>

            <cxad-output-currency-h [value]="calculations.purchasingAdditionalCostsSum"
                [labelText]="'components.financingTab.financingplan.purchasingAdditionalCostsSum' | translate">
            </cxad-output-currency-h>

            <!-- Eigenmittel 1 -->
            <cxad-input-currency-h [control]="financingMapForm.controls['cash']"
                [labelText]="'components.financingTab.financingplan.cash' | translate">
            </cxad-input-currency-h>

            <cxad-output-currency-h
                *ngFor="let marketValue of calculations.realestateMarketValues; let marketValueIdx = index"
                [value]="!!marketValue.marketValue ? marketValue.marketValue : 0"
                [labelText]="('components.financingTab.financingplan.salesRevenue' | translate) + ' ' + marketValue.label">
            </cxad-output-currency-h>

            <cxad-checkbox-input-h [control]="financingMapForm.controls['salesRevenue']"
                [labelText]="'components.financingTab.financingplan.salesRevenue' | translate"
                [comment]="'components.financingTab.financingplan.salesRevenueInputComment' | translate"
                [checkBoxText]="'components.financingTab.financingplan.prefinancingInsurance' | translate"
                [checkBoxControl]="financingMapForm.controls['prefinancingSales']">
            </cxad-checkbox-input-h>

            <!-- Eigenmittel 2 -->
            <cxad-checkbox-input-h [control]="financingMapForm.controls['redemptionInsurance']"
                [labelText]="'components.financingTab.financingplan.redemptionInsurance' | translate"
                [checkBoxText]="'components.financingTab.financingplan.prefinancingInsurance' | translate"
                [checkBoxControl]="financingMapForm.controls['prefinancingInsurance']">
            </cxad-checkbox-input-h>

            <cxad-checkbox-input-h [control]="financingMapForm.controls['bausparCreditBalance']"
                [labelText]="'components.financingTab.financingplan.bausparCreditBalance' | translate"
                [checkBoxText]="'components.financingTab.financingplan.prefinancingBausparCreditBalance' | translate"
                [checkBoxControl]="financingMapForm.controls['prefinancingBausparCreditBalance']">
            </cxad-checkbox-input-h>

            <!-- Sonstige Mittel -->
            <cxad-checkbox-input-h [control]="financingMapForm.controls['funding']"
                [labelText]="'components.financingTab.financingplan.funding' | translate"
                [checkBoxText]="'components.financingTab.financingplan.prefinancingFunding' | translate"
                [checkBoxControl]="financingMapForm.controls['prefinancingFunding']">
            </cxad-checkbox-input-h>
            <cxad-checkbox-input-h [control]="financingMapForm.controls['otherOwnCapital']"
                [labelText]="'components.financingTab.financingplan.otherOwnCapital' | translate"
                [checkBoxText]="'components.financingTab.financingplan.prefinancingOtherOwnCapital' | translate"
                [checkBoxControl]="financingMapForm.controls['prefinancingOtherOwnCapital']"
                [comment]="'components.financingTab.financingplan.otherOwnCapitalComment' | translate">
            </cxad-checkbox-input-h>

            <!-- Finanzierungsbedarf -->
            <cxad-output-currency-h [value]="calculations.shortFinancingRequirement"
                [labelText]="'components.financingTab.financingplan.shortFinancingRequirement' | translate">
            </cxad-output-currency-h>
            <cxad-output-currency-h [value]="calculations.longFinancingRequirementNetto"
                [labelText]="'components.financingTab.financingplan.longFinancingRequirementNetto' | translate">
            </cxad-output-currency-h>

            <!-- Anm. Finanzierungsplan -->
            <cxad-textarea fxFlex="100" [rows]="10" [control]="financingMapForm.controls['financingNotes']"
                [dynamicRows]="true" [labelText]="'components.financingTab.financingplan.financingNotes' | translate">
            </cxad-textarea>
        </div>
    </form>
    <div fxLayout="row" fxLayoutAlign="end center" class="next-btn">
        <button mat-flat-button (click)="onNext()" type="button" color="primary">
            {{ 'global.buttons.next' | translate }}
        </button>
    </div>
</waiter-component>