<ng-container>
    <div class="welcome-container">
        <div class="gradient">
            <div class="banner">
                <div *ngIf="(customer$ | async) as c">
                    <p class="bannertext bannertext1">{{'components.welcome.welcome' | translate}}</p>
                    <p class="bannertext bannertext1">{{'components.welcome.financingPortal' | translate}}</p>
                    <p class="bannertext bannertext1">
                        {{ c.gender !== null && c.gender !== undefined ? (c.gender | finprocessEnumTranslation:'Gender')
                        : ''}}
                        {{c?.title}} {{c?.firstName}} {{c?.lastName}}
                    </p>
                    <p class="bannertext bannertext2">{{'components.welcome.afterLogging' | translate }}</p>
                    <button mat-flat-button class="btn" color="primary" (click)="navigate('../' + routingParams.FINANCING_MODULE)">{{'components.welcome.goToFinancing' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="contentdiv" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-evenly center">
        <div class="contentshow" (click)="navigate('../' + routingParams.FINANCING_MODULE)">
            <span matBadge="{{ msgCount$ | async }}" matBadgeSize="large" matBadgeOverlap="true">
                <br>  Damit das Badge oberhalb des Icons angezeigt wird
                <mat-icon svgIcon="cxad-person" color="primary"></mat-icon>
            </span>
            <h3 class='contenth3'>{{'components.welcome.myData' | translate }}</h3>
            <p class='contentregular'>{{'components.welcome.myDataMsg' | translate }}</p>
        </div> -->
        <!--
        [Improvement] Finanzierungsstatus ausblenden #1469
        <div class="contentshow" (click)="navigate('../' + routingParams.FINANCING_MODULE + '/status')">
        <mat-icon svgIcon="cxad-status" color="primary"></mat-icon>
        <img src='../../../assets/icons/Icon/status_finanzierung.svg'>
        <h3 class='contenth3'>{{'components.welcome.status' | translate }}</h3>
        <p class='contentregular'>{{'components.welcome.statusMsg' | translate }}</p>
        </div>
        -->
    <!-- </div> -->

</ng-container>
