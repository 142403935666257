<div class="background">
    <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayoutAlign="start center">
            <mat-icon class="logo" routerLink="/welcome" fxHide.gt-sm svgIcon="bank-austria-mobile"></mat-icon>
            <mat-icon class="logo" routerLink="/welcome" fxHide fxHide.gt-sm="false" svgIcon="bank-austria"></mat-icon>
            <h4 fxHide.gt-sm svgIcon="bank-austria-mobile" class="app-title">{{ 'components.applicationTitle' | translate}}</h4>
            <h2 fxHide fxHide.gt-sm="false" class="app-title">{{ 'components.applicationTitle' | translate}}</h2>
        </div>
    </div>
    <cxad-footer></cxad-footer>
</div>