<mat-dialog-content>
    <form *ngIf="!!form && !!households" [formGroup]="form">
        <h2>{{'components.financingTab.debtor.addDebtorTooltip' | translate}}</h2>
        <cxad-input [control]="form.controls['firstName']"
            [labelText]="'components.financingTab.debtor.firstName' | translate">
        </cxad-input>
        <cxad-input [control]="form.controls['lastName']"
            [labelText]="'components.financingTab.debtor.lastName' | translate">
        </cxad-input>
        <cxad-select [allowNoSelection]="false" [array]="households" [control]="form.controls['household']"
            [customLabel]="'components.financingTab.household.title' | translate">
        </cxad-select>
        <ucba-boolean [control]="form.controls['copyAddress']"
            [labelText]="'components.financingTab.debtor.copyAddress' | translate">
        </ucba-boolean>
    </form>
</mat-dialog-content>
<mat-dialog-actions fxLayout="column-reverse" fxLayout.gt-xs="row" fxLayoutAlign="space-evenly none">
    <button fxFlex="100" fxFlex.gt-xs="40" mat-flat-button color="secondary" (click)="dismiss()">Abbrechen</button>
    <button [disabled]="!form || form.invalid" fxFlex="100" fxFlex.gt-xs="40" mat-flat-button color="primary"
        (click)="save()">Speichern</button>
</mat-dialog-actions>