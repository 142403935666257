import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { AdditionalFacilities, AdditionalHeatingSystem, AnnexType, BasementConstruction, BasementUsage, BathroomFacilities, BuildingMaterialType, Collateralization, FlatOrientation, GarageFacilities, GarageLocation, HeatingSystem, HeatingSystemType, ObjectPurposeType, Orientation, OutsideFacilities, ParkingSpace, RealEstateType, RoofConstruction, Terrace, WintergardenType } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { CurrencyInputType } from '@ucba/components';
import { GlobalSettings, clearInvalidFormValues } from '@ucba/sdk';
import { FinancingMapStatus, YesNo } from '@ucba/sdk/enums';
import { IFieldCardVisibilityRealEstate } from '@ucba/sdk/interfaces';
import { IRealEstateFormModel, IRealEstateModel } from '@ucba/sdk/models';
import { DataService, HelperService, ModelFactoryService, ValidationService } from '@ucba/sdk/services';
import { DocumentState, FinancingMapState, LiabilityState, NewLiabilityState, RealEstateState } from '@ucba/sdk/statemanagement/states';
import { REALESTATE_FORM_VALIDATION_MAP } from '@ucba/sdk/validations';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

/**
 * Realestate Komponente
 */
@Component({
    selector: 'cxad-realestate',
    templateUrl: './realestate.component.html',
    styleUrls: ['./realestate.component.scss'],
})
export class RealestateComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

    public form: FormGroup | undefined;
    public selectedRealestateIndex!: number;
    public realestates: Array<IRealEstateModel> = [];

    public readonly maxRealEstates = GlobalSettings.maxRealEstates;

    public realEstateType = RealEstateType;
    public yesno = YesNo;
    public buildingMaterialType = BuildingMaterialType;
    public basementUsage = BasementUsage;
    public basementConstruction = BasementConstruction;
    public garageLocation = GarageLocation;
    public parkingSpace = ParkingSpace;
    public terrace = Terrace;
    public wintergardenType = WintergardenType;
    public annexType = AnnexType;
    public garageFacilities = GarageFacilities;
    public livingRoomOrientation = Orientation;
    public flatOrientation = FlatOrientation;
    public heatingSystem = HeatingSystem;
    public heatingSystemType = HeatingSystemType;
    public additionalHeatingSystem = AdditionalHeatingSystem;
    public collateralization = Collateralization;
    public realEstateLabels: string[] = [];

    // dynamische Enums
    public roofConstruction = RoofConstruction;
    public mainBathroomFacilities = BathroomFacilities;
    public outsideFacilities = OutsideFacilities;
    public additionalFacilities = AdditionalFacilities;
    public objectPurposeType = ObjectPurposeType;


    public currencyInputType = CurrencyInputType;

    public visibleMap: IFieldCardVisibilityRealEstate | undefined;

    public isEditingTitle = false;


    /** Notifier wenn View verlassen wird */
    private viewLeft$ = new Subject<void>();

    @ViewChild('scrollWrapper') public scrollWrapper?: ElementRef;
    public addShadow = false;
    private isScrollListenerAdded = false;

    /**
     * Standard Konstruktor
     *
     * @param {UntypedFormBuilder} fb UntypedFormBuilder incjector
     * @param {Store} store Store injector
     * @param {TranslateService} translate TranslateService injector
     * @param {NotificationService} notificationService NotificationService injector
     * @param {DataService} dataService DataService injector
     * @param {ValidationService} validation ValidationService injector
     * @param { Router } router Router injector
     * @param { ActivatedRoute } route ActivatedRoute injector
     * @param { ElementRef } elementRef ElementRef injector
     * @param {Renderer2} renderer Renderer2 injector
     */
    public constructor(
        private fb: UntypedFormBuilder,
        private store: Store,
        private translate: TranslateService,
        private notificationService: NotificationService,
        private dataService: DataService,
        public validation: ValidationService,
        private router: Router,
        private route: ActivatedRoute,
        private elementRef: ElementRef<HTMLElement>,
        private renderer: Renderer2,
    ) { }

    /**
     * convertiert ein Data model objekt in ein Formobjekt
     *
     * @param  {IRealEstateFormModel} model data objekt
     * @returns {IRealEstateModel} daten objekt
     */
    private static modelToFormObject(model: IRealEstateModel): Partial<IRealEstateFormModel> {
        return {
            ...model,
            underConstruction: HelperService.booleanToYesNo(model.underConstruction),
            prefabricatedConstruction: HelperService.booleanToYesNo(model.prefabricatedConstruction),
            willBeRentedOut: HelperService.booleanToYesNo(model.willBeRentedOut),

            basementConstruction: HelperService.convertFlagToArray(model.basementConstruction, BasementConstruction) as BasementConstruction[],
            garageFacilities: HelperService.convertFlagToArray(model.garageFacilities, GarageFacilities) as GarageFacilities[],
            parkingSpace: HelperService.convertFlagToArray(model.parkingSpace, ParkingSpace) as ParkingSpace[],
            mainBathroomFacilities: HelperService.convertFlagToArray(model.mainBathroomFacilities, BathroomFacilities) as BathroomFacilities[],
            additionalHeatingSystem: HelperService.convertFlagToArray(model.additionalHeatingSystem, AdditionalHeatingSystem) as AdditionalHeatingSystem[],
            outsideFacilities: HelperService.convertFlagToArray(model.outsideFacilities, OutsideFacilities) as OutsideFacilities[],
            additionalFacilities: HelperService.convertFlagToArray(model.additionalFacilities, AdditionalFacilities) as AdditionalFacilities[],
        }
    }


    /**
     * gibt abhängig des objectType den passenden collateralization zurück
     *
     * @param {ObjectPurposeType} objectType objectType
     * @returns {Collateralization} collateralization
     */
    // eslint-disable-next-line class-methods-use-this
    private static getCollateralization(objectType?: ObjectPurposeType): Collateralization | undefined {
        if (HelperService.hasValue(objectType)) {
            switch (objectType) {
                case ObjectPurposeType.Owned: return Collateralization.NewFinancing;
                case ObjectPurposeType.ForSale: return Collateralization.InterimFinancing;
                case ObjectPurposeType.Finance: return Collateralization.NewFinancing;
                default: return undefined;
            }
        }
        else {
            return undefined;
        }
    }

    /**
     * Angular Hook um Werte zu initialisieren
     */
    public ngOnInit(): void {

        this.store.select(RealEstateState.current)
            .pipe(takeUntil(this.viewLeft$))
            .subscribe(realestates => {
                const previousRealestate = this.selectedRealestateIndex !== undefined ? this.realestates[this.selectedRealestateIndex] : undefined;
                this.realestates = realestates.filter(realestate => realestate.objectPurpose === ObjectPurposeType.Finance)
                    .concat(realestates.filter(realestate => realestate.objectPurpose !== ObjectPurposeType.Finance));

                for (const realestate of realestates) {
                    if (!!previousRealestate && previousRealestate.id === realestate.id) {
                        this.updateFormular(realestate);
                    }
                }
                //prüft ob ausgewählter Objekt noch existiert, es kann sein dass aus der Filiale entfernt ist
                if (!!this.selectedRealestateIndex && !this.realestates[this.selectedRealestateIndex]) {
                    this.onSelectRealestate(0);
                }
                if (!this.form) {
                    const financingRealestate = this.realestates.find(realestate => realestate.objectPurpose === ObjectPurposeType.Finance);

                    if (!!financingRealestate) {
                        this.selectedRealestateIndex = 0;
                        this.initFormular(financingRealestate);
                    }
                }

                this.realEstateLabels = realestates.map((realEstate, realEstateIdx) =>
                    HelperService.calcRealEstateLabel(
                        realEstate,
                        this.translate.instant('components.financingTab.realEstate.labelRealEstate', { i: realEstateIdx + 1 }),
                    ));
            });
    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
    }

    /**
     * Angular Hook nachdem die View geladen wurde
     */
    public ngAfterViewInit(): void {
        const p = this.elementRef.nativeElement.parentElement?.parentElement;

        if (!!p?.scrollTo) {
            p.scrollTo(0, 0);
        }
    }

    /**
     * Angular Hook nachdem die View geladen wurde
     */
    public ngAfterViewChecked() {
        if (this.scrollWrapper && !this.isScrollListenerAdded) {
            this.renderer.listen(this.scrollWrapper.nativeElement, 'scroll', () => {
                if (this.scrollWrapper?.nativeElement.scrollTop > 0) {
                    this.addShadow = true;
                } else {
                    this.addShadow = false;
                }
            });
            this.isScrollListenerAdded = true;
        }
    }

    /**
     * wenn auf den Weiter Button gedrückt wird, wird zum nächsten Tab gewechselt
     *
     * @returns {Promise} navigations Promise
     */
    public onNext(): Promise<boolean> {
        return this.router.navigate(['..', 'debtor'], { relativeTo: this.route });
    }

    /**
     * Fügt eine Liegenschaft hinzu
     *
     * @returns {Promise<void>} Void Promise
     */
    public async addRealestate(): Promise<void> {
        const financingId = this.store.selectSnapshot(FinancingMapState.currentId);

        if (!!financingId && this.realestates.length < this.maxRealEstates) {
            const newRealEstate = ModelFactoryService.createRealEstateModel(financingId);
            await this.dataService.createRealEstate(newRealEstate);
        }
    }

    /**
     * Setzt den neuen Wert für den Titel beim Verlassen des Inputs
     */
    public onEditRealestateTitle(): void {
        if (this.form?.get('name')?.valid) {
            this.isEditingTitle = false;
        }
        else {
            this.isEditingTitle = true;
        }
    }

    /**
     * Entfernt eine Liegenschaft
     *
     * @param  {IRealEstateModel} realestate Zu entfernende Liegenschaft
     */
    public removeRealestate(realestate: IRealEstateModel): void {
        const usedLiabilities = this.store.selectSnapshot(LiabilityState.current).filter(l => l.securedRealEstateId === realestate.id);
        const usedNewLiabilities = this.store.selectSnapshot(NewLiabilityState.current).filter(l => l.securedRealEstateId === realestate.id);
        const isUsed = usedLiabilities.length > 0 || usedNewLiabilities.length > 0;
        const financingMapStatus = this.store.selectSnapshot(FinancingMapState.current)?.status;
        const hasRealEstateDocuments = this.store.selectSnapshot(DocumentState.current).some(d => d.realEstateId === realestate.id);

        let translationKey = '';
        if (isUsed && hasRealEstateDocuments) {
            translationKey = 'components.financingTab.realEstate.removeRealestateInUseAndWithDocumentsText';
        }
        else if (isUsed && !hasRealEstateDocuments) {
            translationKey = 'components.financingTab.realEstate.removeRealestateInUseText';
        }
        else if (!isUsed && hasRealEstateDocuments) {
            translationKey = 'components.financingTab.realEstate.removeRealestateWithDocumentsText';
        }
        else {
            translationKey = 'components.financingTab.realEstate.removeRealestateText';
        }

        if (financingMapStatus === FinancingMapStatus.Open) {
            this.notificationService.confirmYesNo(
                this.translate.instant('components.financingTab.realEstate.removeRealestateTitle'),
                this.translate.instant(translationKey),
            )
                .pipe(take(1))
                .subscribe(role => {
                    if (role === 'submit') {
                        if (isUsed) {
                            const requests: Promise<void>[] = [];

                            for (const liab of usedLiabilities) {
                                requests.push(this.dataService.updateLiability({
                                    ...liab,
                                    securedRealEstateId: undefined,
                                }));
                            }

                            for (const newLiab of usedNewLiabilities) {
                                requests.push(this.dataService.updateNewLiability({
                                    ...newLiab,
                                    securedRealEstateId: undefined,
                                }));
                            }

                            Promise.all(requests).catch(e => { throw e; });
                        }

                        const currentRealestate = this.selectedRealestateIndex !== undefined ? this.realestates[this.selectedRealestateIndex] : undefined;
                        if (!!currentRealestate && currentRealestate.id === realestate.id) {
                            this.selectedRealestateIndex = 0;
                            this.initFormular(this.realestates[this.selectedRealestateIndex]);
                        }

                        this.dataService.deleteRealEstate(realestate.id).then(() => {
                            this.notificationService.toast(this.translate.instant('components.financingTab.realEstate.removeRealestateSucess'));
                        }).catch(e => { throw e; })
                    }
                });
        }
    }

    /**
     * Handler wenn ein Chip angeklickt wird um eine Liegenschaft auszuwählen
     *
     * @param {number} index Index der Liegenschaft
     */
    public onSelectRealestate(index: number): void {
        this.selectedRealestateIndex = index;
        this.initFormular(this.realestates[index]);
        this.scrollWrapper?.nativeElement.scrollTo({ top: 0 });
    }

    /**
     * aktualisiert das Formular
     *
     * @param { IRealEstateModel } realEstate das Objekt zum updaten
     */
    private updateFormular(realEstate: IRealEstateModel): void {

        this.updateVisibleMapAndEnums(realEstate);

        const realEstateValidation = REALESTATE_FORM_VALIDATION_MAP['realEstateModel'];
        const formModel = RealestateComponent.modelToFormObject(realEstate);

        if (!!this.form) {
            for (const fieldname in realEstateValidation) {
                if (fieldname in realEstateValidation) {
                    const field = this.form.get(fieldname);
                    const value = (formModel as unknown as Record<string, unknown>)[fieldname] as number | string | boolean;

                    if (!!field && HelperService.getValueOrNull(field.value) !== HelperService.getValueOrNull(value) && (field.valid || HelperService.hasValue(value))) {
                        field.patchValue(value, { onlySelf: true })
                    }
                }
            }

            this.validation.setValidatorsForForm(realEstateValidation, this.form, undefined, realEstate);
        }
    }

    /**
     * initialisiert das Formular
     *
     * @param { IRealEstateModel } realEstate das Objekt zum initialisieren
     */
    private initFormular(realEstate: IRealEstateModel): void {
        if (!!realEstate) {

            this.updateVisibleMapAndEnums(realEstate);
            const realEstateValidation = REALESTATE_FORM_VALIDATION_MAP['realEstateModel'];
            const formModel = RealestateComponent.modelToFormObject(realEstate);

            const controlCfg: Record<string, unknown> = {
                id: [realEstate.id], // only for identification
                financingMapId: [realEstate.financingMapId],
            };


            for (const fieldname in realEstateValidation) {
                if (fieldname in realEstateValidation) {
                    const value = (formModel as unknown as Record<string, unknown>)[fieldname] as number | string | boolean;
                    controlCfg[fieldname] = this.fb.control(HelperService.getValueOrNull(value), { updateOn: realEstateValidation[fieldname].changeByChange ? 'change' : 'blur' });
                }
            }

            const realestateForm = this.fb.group(controlCfg);

            this.validation.setValidatorsForForm(realEstateValidation, realestateForm, undefined, realEstate);

            realestateForm.valueChanges
                .pipe(
                    takeUntil(this.viewLeft$),
                    clearInvalidFormValues(realestateForm),
                )
                .subscribe(change => {
                    const toUpdate = this.formToModelObject(change);
                    toUpdate.collateralization = RealestateComponent.getCollateralization(toUpdate.objectPurpose),
                        this.dataService.updateRealEstate(toUpdate).catch(e => { throw e; });
                });

            this.form = realestateForm;
        }
    }

    /**
     * entfernt die Werte welche nicht mehr angezeigt werden
     *
     * @param {Partial<IRealEstateModel>} realEstateModel zu prüfende Liegenschaft
     * @returns {Partial<IRealEstateModel>} Liegenschaft mit nur gültigen Werten
     */
    // eslint-disable-next-line complexity
    private checkConditionsForFields(realEstateModel: Partial<IRealEstateModel>): Partial<IRealEstateModel> {
        return {
            id: realEstateModel.id,
            financingMapId: realEstateModel.financingMapId,

            // Objektangaben
            name: realEstateModel.name,
            type: realEstateModel.type,
            objectPurpose: realEstateModel.objectPurpose,
            collateralization: realEstateModel.collateralization,
            willBeRentedOut: realEstateModel.willBeRentedOut,
            landRegisterNumber: realEstateModel.landRegisterNumber === '' ? null : realEstateModel.landRegisterNumber,
            propertyRegisterNumber: realEstateModel.propertyRegisterNumber === '' ? null : realEstateModel.propertyRegisterNumber,

            // Adresse
            street: realEstateModel.street === '' ? null : realEstateModel.street,
            streetNumber: realEstateModel.streetNumber === '' ? null : realEstateModel.streetNumber,
            zip: realEstateModel.zip === '' ? null : realEstateModel.zip,
            city: realEstateModel.city === '' ? null : realEstateModel.city,

            // Zusatz Adresse
            stairway: this.visibleMap?.cardAdditionalAddress && realEstateModel.stairway !== '' ? realEstateModel.stairway : null,
            top: this.visibleMap?.cardAdditionalAddress && realEstateModel.top !== '' ? realEstateModel.top : null,

            // validierte Adresse
            // wird direkt gesetzt

            // Längen und Breitengrad
            latitude: realEstateModel.latitude,
            longitude: realEstateModel.longitude,

            // Weitere Objektangaben
            lotSize: this.visibleMap?.cardOtherObjectSpecifications && this.visibleMap?.fieldLotSize ? realEstateModel.lotSize : null,
            energyIndex: this.visibleMap?.cardOtherObjectSpecifications && this.visibleMap?.fieldEergyIndex ? realEstateModel.energyIndex : null,
            floor: this.visibleMap?.cardOtherObjectSpecifications && this.visibleMap?.fieldFloor ? realEstateModel.floor : null,
            lotNumber: this.visibleMap?.cardOtherObjectSpecifications ? realEstateModel.lotNumber : null,

            // Baujahr
            underConstruction: this.visibleMap?.cardYearOfConstruction ? realEstateModel.underConstruction : null,
            yearOfConstruction: this.visibleMap?.cardYearOfConstruction && this.visibleMap?.fieldYearOfConstruction ? realEstateModel.yearOfConstruction : null,
            startOfConstruction: this.visibleMap?.cardYearOfConstruction && this.visibleMap?.fieldStartOfConstruction ? realEstateModel.startOfConstruction : null,
            endOfConstruction: this.visibleMap?.cardYearOfConstruction && this.visibleMap?.fieldEndOfConstruction ? realEstateModel.endOfConstruction : null,

            // ergänzende Objektangaben
            prefabricatedConstruction: this.visibleMap?.cardAdditionalInformation ? realEstateModel.prefabricatedConstruction : null,
            buildingMaterialType: this.visibleMap?.cardAdditionalInformation ? realEstateModel.buildingMaterialType : null,

            // Treuhänder
            trusteeName: this.visibleMap?.cardTrustee && realEstateModel.trusteeName !== '' ? realEstateModel.trusteeName : null,
            trusteePhoneNumber: this.visibleMap?.cardTrustee && realEstateModel.trusteePhoneNumber !== '' ? realEstateModel.trusteePhoneNumber : null,
            trusteeFaxNumber: this.visibleMap?.cardTrustee && realEstateModel.trusteeFaxNumber !== '' ? realEstateModel.trusteeFaxNumber : null,

            // Treuhänder Adresse
            trusteeZip: this.visibleMap?.cardTrusteeAddress && realEstateModel.trusteeZip !== '' ? realEstateModel.trusteeZip : null,
            trusteeCity: this.visibleMap?.cardTrusteeAddress && realEstateModel.trusteeCity !== '' ? realEstateModel.trusteeCity : null,
            trusteeStreet: this.visibleMap?.cardTrusteeAddress && realEstateModel.trusteeStreet !== '' ? realEstateModel.trusteeStreet : null,
            trusteeStreetNumber: this.visibleMap?.cardTrusteeAddress && realEstateModel.trusteeStreetNumber !== '' ? realEstateModel.trusteeStreetNumber : null,

            // Flächen
            netRoomArea: this.visibleMap?.cardAreas && this.visibleMap?.fieldNetRoomArea ? realEstateModel.netRoomArea : null,
            areaBasement: this.visibleMap?.cardAreas ? realEstateModel.areaBasement : null,
            areaGroundFloor: this.visibleMap?.cardAreas && this.visibleMap?.fieldAreaGroundFloor ? realEstateModel.areaGroundFloor : null,

            // Flächen weitere Geschosse
            areaUpperFloor: this.visibleMap?.cardOtherFloors ? realEstateModel.areaUpperFloor : null,
            areaAdditionalUpperFloor: this.visibleMap?.cardOtherFloors ? realEstateModel.areaAdditionalUpperFloor : null,
            areaTopFloor: this.visibleMap?.cardOtherFloors ? realEstateModel.areaTopFloor : null,

            // Sonstige Flächen
            areaLoggia: this.visibleMap?.cardOtherAreas1 ? realEstateModel.areaLoggia : null,
            areaRoofTerrace: this.visibleMap?.cardOtherAreas1 && this.visibleMap?.fieldAreaRoofTerrace ? realEstateModel.areaRoofTerrace : null,
            areaBalcony: this.visibleMap?.cardOtherAreas1 ? realEstateModel.areaBalcony : null,
            areaGarden: this.visibleMap?.cardOtherAreas1 && this.visibleMap?.fieldAreaGarden ? realEstateModel.areaGarden : null,

            // Sonstige Flächen
            areaTerrace: this.visibleMap?.cardOtherAreas2 ? realEstateModel.areaTerrace : null,
            areaWinterGarden: this.visibleMap?.cardOtherAreas2 ? realEstateModel.areaWinterGarden : null,
            areaGarage: this.visibleMap?.cardOtherAreas2 ? realEstateModel.areaGarage : null,
            areaOutbuildings: this.visibleMap?.cardOtherAreas2 ? realEstateModel.areaOutbuildings : null,

            // Keller
            basementUsage: this.visibleMap?.cardBasement ? realEstateModel.basementUsage : null,
            basementConstruction: this.visibleMap?.cardBasement ? realEstateModel.basementConstruction : null,

            // Dachgeschoss
            roofConstruction: this.visibleMap?.cardRoofConstruction ? realEstateModel.roofConstruction : null,

            // Nutzbarkeit/Lage
            terrace: this.visibleMap?.cardUsabilityLocation && this.visibleMap?.fieldTerrace ? realEstateModel.terrace : null,
            wintergardenType: this.visibleMap?.cardUsabilityLocation && this.visibleMap?.fieldWintergardenType ? realEstateModel.wintergardenType : null,
            annexType: this.visibleMap?.cardUsabilityLocation && this.visibleMap?.fieldAnnexType ? realEstateModel.annexType : null,

            // Garage
            garageLocation: this.visibleMap?.cardGarage && this.visibleMap?.fieldGarageLocation ? realEstateModel.garageLocation : null,
            parkingSpace: this.visibleMap?.cardGarage && this.visibleMap?.fieldParkingSpace ? realEstateModel.parkingSpace : null,
            garageFacilities: this.visibleMap?.cardGarage ? realEstateModel.garageFacilities : null,

            // Außensanierung in Jahren
            refurbishmentInnerBuildungStructure: this.visibleMap?.cardOuterBuildingRefurbishment ? realEstateModel.refurbishmentInnerBuildungStructure : null,
            refurbishmentRoof: this.visibleMap?.cardOuterBuildingRefurbishment ? realEstateModel.refurbishmentRoof : null,
            refurbishmentOuterBuildingStructure: this.visibleMap?.cardOuterBuildingRefurbishment ? realEstateModel.refurbishmentOuterBuildingStructure : null,
            refurbishmentCommonArea: this.visibleMap?.cardOuterBuildingRefurbishment && this.visibleMap?.fieldRefurbishmentCommonArea ? realEstateModel.refurbishmentCommonArea : null,

            // Innensanierung in Jahren
            refurbishmentWindow: this.visibleMap?.cardInnerBuildingRefurbishment ? realEstateModel.refurbishmentWindow : null,
            refurbishmentHeating: this.visibleMap?.cardInnerBuildingRefurbishment ? realEstateModel.refurbishmentHeating : null,
            refurbishmentElectricSanitary: this.visibleMap?.cardInnerBuildingRefurbishment ? realEstateModel.refurbishmentElectricSanitary : null,
            refurbishmentWallpapering: this.visibleMap?.cardInnerBuildingRefurbishment ? realEstateModel.refurbishmentWallpapering : null,
            refurbishmentInnerDoorsStairsFloorCovering: this.visibleMap?.cardInnerBuildingRefurbishment ? realEstateModel.refurbishmentInnerDoorsStairsFloorCovering : null,

            // Orientierung
            livingRoomOrientation: this.visibleMap?.cardOrientation && this.visibleMap?.fieldLivingRoomOrientation ? realEstateModel.livingRoomOrientation : null,
            flatOrientation: this.visibleMap?.cardOrientation && this.visibleMap?.fieldFlatOrientation ? realEstateModel.flatOrientation : null,

            // Ausstattung Badezimmer
            bathroomCount: this.visibleMap?.cardMainBathroomFacilities ? realEstateModel.bathroomCount : null,
            mainBathroomFacilities: this.visibleMap?.cardMainBathroomFacilities ? realEstateModel.mainBathroomFacilities : null,

            // Heizung
            heatingSystem: this.visibleMap?.cardHeater ? realEstateModel.heatingSystem : null,
            heatingSystemType: this.visibleMap?.cardHeater ? realEstateModel.heatingSystemType : null,
            additionalHeatingSystem: this.visibleMap?.cardHeater ? realEstateModel.additionalHeatingSystem : null,

            // Ausstattung Außenbereich
            outsideFacilities: this.visibleMap?.cardOutsideFacilities ? realEstateModel.outsideFacilities : null,

            // Weitere Ausstattungen
            additionalFacilities: this.visibleMap?.cardAdditionalFacilities ? realEstateModel.additionalFacilities : null,
        };
    }

    /**
     * convertiert ein Formobjekt zu einem Data model objekt
     *
     * @param  {IRealEstateFormModel} model form objekt
     * @returns {IRealEstateModel} daten objekt
     */
    private formToModelObject(model: IRealEstateFormModel): Partial<IRealEstateModel> {
        const formData = {
            ...model,
            underConstruction: HelperService.yesNoToBoolean(model.underConstruction),
            prefabricatedConstruction: HelperService.yesNoToBoolean(model.prefabricatedConstruction),
            willBeRentedOut: HelperService.yesNoToBoolean(model.willBeRentedOut),

            basementConstruction: HelperService.convertArrayToFlag(model.basementConstruction),
            garageFacilities: HelperService.convertArrayToFlag(model.garageFacilities),
            parkingSpace: HelperService.convertArrayToFlag(model.parkingSpace),
            mainBathroomFacilities: HelperService.convertArrayToFlag(model.mainBathroomFacilities),
            additionalHeatingSystem: HelperService.convertArrayToFlag(model.additionalHeatingSystem),
            outsideFacilities: HelperService.convertArrayToFlag(model.outsideFacilities),
            additionalFacilities: HelperService.convertArrayToFlag(model.additionalFacilities),
        }

        return this.checkConditionsForFields(formData);
    }

    /**
     * Aktualisiert welche Felder und Enums angezeigt werden
     *
     * @param  {IRealEstateModel} realestate Aktuelle Liegenschaft
     */
    private updateVisibleMapAndEnums(realestate: IRealEstateModel): void {
        this.visibleMap = RealEstateState.visibleMap(realestate);
        this.roofConstruction = this.visibleMap.isTypeFlat ? HelperService.removeValuesFromEnum(
            RoofConstruction,
            [RoofConstruction.ResidentialUse, RoofConstruction.NoResidentalUse, RoofConstruction.Expandable],
        ) : RoofConstruction;

        this.mainBathroomFacilities = this.visibleMap.isTypeFlat ? HelperService.removeValuesFromEnum(
            BathroomFacilities,
            [BathroomFacilities.BasinInSeparateToilet, BathroomFacilities.Bidet, BathroomFacilities.DoubleBasin],
        ) : BathroomFacilities;

        this.outsideFacilities = this.visibleMap.isTypeFlat ? HelperService.removeValuesFromEnum(
            OutsideFacilities,
            [OutsideFacilities.HighQualityFence, OutsideFacilities.SwimmingPool, OutsideFacilities.ElaborateGardenArchitecture, OutsideFacilities.Carport,
            OutsideFacilities.GardenNotVisible, OutsideFacilities.RetainingWalls, OutsideFacilities.GardenWatering],
        ) : HelperService.removeValuesFromEnum(OutsideFacilities, [OutsideFacilities.CommonGarden]);

        this.additionalFacilities = this.visibleMap.isTypeFlat ? HelperService.removeValuesFromEnum(
            AdditionalFacilities,
            [AdditionalFacilities.Wintergarden, AdditionalFacilities.SecuritySystem, AdditionalFacilities.PhotovoltaicSystem,
            AdditionalFacilities.ElectricBlinds, AdditionalFacilities.WoodWindows],
        ) : AdditionalFacilities;

        this.objectPurposeType = realestate.objectPurpose !== ObjectPurposeType.Finance ?
            HelperService.removeValuesFromEnum(ObjectPurposeType, [ObjectPurposeType.Finance])
            : HelperService.removeValuesFromEnum(ObjectPurposeType, [ObjectPurposeType.Owned, ObjectPurposeType.ForSale]);

    }
}
