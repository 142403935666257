import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { FinancingMapStatus } from '@ucba/sdk/enums';
import { IFinancingMapLightModel } from '@ucba/sdk/models';
import { DataService, HelperService } from '@ucba/sdk/services';
import { FinancingMapState } from '@ucba/sdk/statemanagement/states';
import { BehaviorSubject, Observable, Subject, combineLatest, filter, map, takeUntil } from 'rxjs';

interface INavItem {
    label: string;
    icon: string;
    routerLink: string;
    isActive: boolean;
    badgeContent$: Observable<string | number>;
    tooltip$: Observable<string>;
}

/**
 * Container Komponente für die Selbstauskunft unter meine Daten
 */
@Component({
    selector: 'cxad-financing-menu',
    templateUrl: './financing-menu.component.html',
    styleUrls: ['./financing-menu.component.scss'],
})
export class FinancingMenuComponent implements OnInit, OnDestroy {

    public navItems: INavItem[] = [];

    /**gibt an wie viele Nachrichten bei dieser Finanzierung noch ungelesen sind */
    private unreadMessageCount$ = new BehaviorSubject<string | number>('');

    public financingMapStatus: FinancingMapStatus | undefined;
    public currentFinancingId: FinancingMapStatus | undefined;

    /** is there any file wich must be sent to VPC */
    private mustSendToVPC$ = new BehaviorSubject<boolean>(false);

    /** Notifier wenn View verlassen wird */
    private viewLeft$ = new Subject<void>();

    public isExpanded = true;

    /**
     * Standard Konstruktor
     * 
     * @param {DataService} dataService Data service
     * @param {Store} store Store injector
     */
    public constructor(
        private dataService: DataService,
        private store: Store,
    ) { }

    /**
     * Angular Hook um Werte zu initialisieren
     */
    public ngOnInit(): void {

        this.checkScreenWidth();
        window.addEventListener('resize', this.checkScreenWidth.bind(this));

        combineLatest([
            this.store.select(FinancingMapState.currentId),
            this.dataService.financingObservable$,
            this.store.select(FinancingMapState.hasSentFileToVPC),
        ])
            .pipe(
                takeUntil(this.viewLeft$),
                filter(([id, financings]) => !!id && Array.isArray(financings)),
                map<[string | undefined, IFinancingMapLightModel[] | null, boolean], [IFinancingMapLightModel | undefined, boolean]>(
                    ([currentId, financings, hasSentFile]) => [financings?.find(({ id }) => id === currentId), hasSentFile]),
            )
            .subscribe(([financing, hasSentFile]) => {
                if (!!financing) {
                    this.financingMapStatus = financing.status ?? undefined;
                    const unreadCount = HelperService.hasValue(financing.unreadMessageCount) && financing.unreadMessageCount > 0 ? financing.unreadMessageCount.toString() : '';
                    this.unreadMessageCount$.next(unreadCount);
                }
                this.mustSendToVPC$.next(hasSentFile);
                this.initializeNavItems();
            });
    }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
    }

    /**
     * Menu zu oder aufklappen basierend auf der Bildschirmgröße
     */
    private checkScreenWidth(): void {
        this.isExpanded = window.matchMedia('(min-width: 959px)').matches;
    }

    /**
     * Initialisiert die Navigationsmenü
     */
    private initializeNavItems(): void {
        this.navItems = [
            {
                label: 'components.icon.financingplan',
                icon: 'cxad-finance-plan',
                routerLink: 'financingplan',
                isActive: false,
                badgeContent$: new BehaviorSubject<string | number>(''),
                tooltip$: new BehaviorSubject<string>('components.icon.financingplan'),
            },
            {
                label: 'components.icon.object',
                icon: 'cxad-object',
                routerLink: 'realestate',
                isActive: false,
                badgeContent$: new BehaviorSubject<string | number>(''),
                tooltip$: new BehaviorSubject<string>('components.icon.object'),
            },
            {
                label: 'components.icon.person',
                icon: 'cxad-person',
                routerLink: 'debtor',
                isActive: false,
                badgeContent$: new BehaviorSubject<string | number>(''),
                tooltip$: new BehaviorSubject<string>('components.icon.person'),
            },
            {
                label: 'components.icon.household',
                icon: 'cxad-household',
                routerLink: 'household',
                isActive: false,
                badgeContent$: new BehaviorSubject<string | number>(''),
                tooltip$: new BehaviorSubject<string>('components.icon.household'),
            },
            {
                label: 'components.icon.upload',
                icon: 'cxad-upload',
                routerLink: 'documents',
                isActive: false,
                badgeContent$: this.mustSendToVPC$.asObservable().pipe(map(hasFiles => (hasFiles ? '!' : ''))),
                tooltip$: this.mustSendToVPC$.asObservable().pipe(map(mustSend => (mustSend ? 'components.financingTab.documents.resubmitWarningTooltip' : 'components.icon.upload'))),
            },
            {
                label: 'components.icon.download',
                icon: 'cxad-download',
                routerLink: 'bankdocuments',
                isActive: false,
                badgeContent$: new BehaviorSubject<string | number>(''),
                tooltip$: new BehaviorSubject<string>('components.icon.download'),
            },
            {
                label: 'components.icon.message',
                icon: 'cxad-message',
                routerLink: 'news',
                isActive: false,
                badgeContent$: this.unreadMessageCount$.asObservable(),
                tooltip$: new BehaviorSubject<string>('components.icon.message'),
            },
        ];
    }
}
